<template>
  <div>
    <div class="text-center pt-2 pb-2" v-if="!mountedFinish">
      <a-spin />
    </div>
    <template v-else>
      <div style="margin-bottom: 10px" v-if="toFieldFromOtherBlueprint">
        <a-select
          class="m-0 mb-1"
          v-model:value="otherSelectedBlueprintId"
          :defaultValue="selectedFromOtherBlueprintValue"
          placeholder="Select a Business Object..."
          option-label-prop="label"
          @change="selectFieldFromOtherBlueprint"
          allowClear
          style="width: 100%"
          :filter-option="filterOption"
          show-search
        >
          <a-select-option v-for="item of otherBlueprints" :key="item._id" :value="item._id" :label="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-alert type="success" v-if="otherBlueprints.length">
          <template #message>
            We found a field reference to <b>{{ selectedBlueprint.name }}</b> in {{ otherBlueprints.length }} Business Object
          </template>
        </a-alert>
        <a-alert type="error" v-else>
          <template #message>
            I have not found a field reference to
            <b>{{ selectedBlueprint.name }}</b> in no Business Objects
          </template>
        </a-alert>
      </div>
      <div
        :class="cardView === false ? '' : 'card'"
        v-if="((toFieldFromOtherBlueprint && otherSelectedBlueprintId) || !toFieldFromOtherBlueprint) && mountedFinish"
      >
        <div :class="cardView === false ? '' : 'card-body p-3'">
          <div v-for="(item, key) in referenceField" :key="key" :style="key ? 'margin-top: 10px' : ''">
            <template v-if="!keyLimit || key < keyLimit">
              <template v-if="key !== hideIndex">
                <span v-if="key === 0 || (key === 1 && hideIndex === 0)">{{ addTextToFirstSelector }}</span
                ><b>{{ item.blueprintName }}</b> fields:<br />
                <div class="row">
                  <div :class="noCondition ? 'col-md-12' : 'col-md-6'">
                    <a-select
                      allowClear
                      @change="selectField($event, item, key)"
                      placeholder="Select a field..."
                      :defaultValue="item?.fieldId ? item.fieldId : selectedFromBlueprintValue(item.blueprintId, key)"
                      style="width: 100%"
                      :filter-option="filterOption"
                      show-search
                      option-label-prop="label"
                    >
                      <!--                  <template v-if="  justReferencesAndFieldType === 'number' || (!justReferencesAndFieldType && !forceShowId)">-->
                      <!--                    <a-select-option value="_id" >ID - (number)</a-select-option>-->
                      <!--                  </template>-->

                      <a-select-option
                        v-if="defaultSettings?.optionChangedData && !key"
                        :key="'_changedData'"
                        value="_changedData"
                        :label="defaultSettings.optionChangedData"
                      >
                        {{ defaultSettings.optionChangedData }}
                      </a-select-option>

                      <template v-if="!justReferenceFields || (justReferenceFields && key)">
                        <template
                          v-if="justReferencesAndFieldType === 'number' || justReferencesAndFieldType === 'reference' || !justReferencesAndFieldType"
                        >
                          <a-select-option :key="'_id'" value="_id" v-if="!key || forceShowId" :label="'ID - (number)'"
                            >ID - (number)</a-select-option
                          >
                        </template>
                        <template v-if="justReferencesAndFieldType === 'string' || !justReferencesAndFieldType">
                          <a-select-option value="_ownerId" :label="'Owner - (string)'">Owner - (string)</a-select-option>
                        </template>
                      </template>
                      <template v-if="item.fields">
                        <template
                          v-for="field of item.fields.filter(item =>
                            justSingleReferenceFields ? item.structure?.elementStructure?.type !== 'reference' : true
                          )"
                        >
                          <template
                            v-if="
                              (justReferenceFields && (field.structure?.elementStructure?.type ?? field.structure.type) === 'reference') ||
                              !justReferenceFields ||
                              key
                            "
                          >
                            <template
                              v-if="
                                field?.structure?.type === 'reference' ||
                                justReferencesAndFieldType ===
                                  (field?.structure?.type === 'array' ? field?.structure?.elementStructure?.type : field?.structure?.type) ||
                                !justReferencesAndFieldType
                              "
                            >
                              <a-select-option
                                :key="field._id"
                                :value="field._id"
                                :label="`${field.label} (${
                                  field.structure.type === 'array'
                                    ? field.structure.type + '[' + field.structure?.elementStructure?.type + ']'
                                    : (field.structure?.elementStructure?.type ?? field.structure.type)
                                })`"
                              >
                                {{ field.label }}
                                ({{
                                  field.structure.type === 'array'
                                    ? field.structure.type + '[' + field.structure?.elementStructure?.type + ']'
                                    : (field.structure?.elementStructure?.type ?? field.structure.type)
                                }})
                              </a-select-option>
                            </template>
                          </template>
                        </template>
                      </template>
                      <template v-if="justReferencesAndFieldType === 'date' || !justReferencesAndFieldType">
                        <a-select-option
                          v-if="!justReferenceFields || (justReferenceFields && key)"
                          :key="'_createdAt'"
                          value="_createdAt"
                          :label="'Created At - (date)'"
                          >Created At - (date)</a-select-option
                        >
                      </template>

                      <!--                      <template v-if="otherBlueprint && otherFieldsFromOtherBlueprints.length">-->
                      <template v-if="Array.isArray(otherFieldsFromOtherBlueprints) && otherBlueprint">
                        <a-select-option value="disabled" disabled> --- Other fields from other blueprints --- </a-select-option>

                        <template v-for="blueprint of item.otherFieldsFromOtherBlueprints" :key="blueprint._id">
                          <template v-for="field of blueprint.fields" :key="field._id + '_otherBlueprint'">
                            <a-select-option
                              :value="'other_' + blueprint._id + '-' + field._id"
                              :label="`${blueprint.name} -> ${field.label}
                            (${
                              field.structure.type === 'array'
                                ? field.structure.type + '[' + field.structure?.elementStructure?.type + ']'
                                : (field.structure?.elementStructure?.type ?? field.structure.type)
                            })`"
                            >
                              {{ blueprint.name }} -> {{ field.label }}

                              ({{
                                field.structure.type === 'array'
                                  ? field.structure.type + '[' + field.structure?.elementStructure?.type + ']'
                                  : (field.structure?.elementStructure?.type ?? field.structure.type)
                              }})
                            </a-select-option>
                          </template>
                        </template>
                      </template>
                    </a-select>
                    <template v-if="selectedFiedId">
                      <a-select
                        v-if="item.fields.find(it => it._id === selectedFiedId && (it?.widget?.extract ? it.widget.extract.length : 0 > 1))"
                        :placeholder="'Extract'"
                        style="width: 100%; margin-top: 10px"
                        value=""
                        v-model="getFromInstance"
                        @change="selectField(selectedFiedId, item, key)"
                      >
                        <a-select-option
                          v-for="it in item.fields.find(it => it._id === selectedFiedId).widget.extract"
                          :key="it.value"
                          :value="it.value"
                        >
                          {{ it.label }} ({{ it.type }})
                        </a-select-option>
                      </a-select>
                    </template>
                  </div>
                  <div class="col-md-6" v-if="mainBlueprint">
                    <div>
                      <template v-if="autoFieldConditions">
                        <template v-for="autoField of autoFieldConditionsData" :key="autoField.index">
                          <span class="mr-2" v-if="autoField.blueprintId === item.blueprintId">
                            <template v-for="(condition, key) of autoField.conditions" :key="key">
                              <span>
                                <a-tooltip placement="top">
                                  <template #title>
                                    <span>{{ condition.description }}</span>
                                  </template>
                                  <a-switch
                                    v-model:checked="condition.active"
                                    :checked-children="condition.label"
                                    :un-checked-children="condition.label"
                                    @change="$emit('reloadField')"
                                  />
                                </a-tooltip>
                              </span>
                            </template>
                          </span>
                        </template>
                      </template>
                      <a-button
                        v-if="fieldConditionsDataAttributes.find(f => f.blueprintId === item.blueprintId)"
                        type="primary"
                        size="small"
                        @click="openFieldConditionsModal(item, key)"
                      >
                        <FormOutlined /> Edit condition
                      </a-button>
                      <a-button v-else-if="!noCondition" size="small" @click="openFieldConditionsModal(item, key)">
                        <PlusOutlined /> Add condition
                      </a-button>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>

      <!-- From here -->
      <a-modal
        v-model:open="fieldConditionsModal"
        width="1100px"
        @cancel="closeFieldConditionsModal"
        @ok="closeFieldConditionsModalAndSave"
        ref="fieldConditions"
        :title="fieldConditionsBlueprint?.blueprintName + ' fields conditions'"
      >
        <div>
          <FieldConditions
            v-if="fieldConditionsModal"
            :conditionsAcceptFieldValue="conditionsAcceptFieldValue"
            :fieldConditionsBlueprint="fieldConditionsBlueprint"
            :fieldConditions="fieldConditionsData"
            :parentReferenceField="referenceField"
            :blueprints="blueprints"
            :selectedBlueprint="selectedBlueprint"
            :toFieldFromOtherBlueprint="toFieldFromOtherBlueprint"
            :justReferenceFields="justReferenceFields"
            :autoFieldConditions="autoFieldConditions"
            :mainBlueprint="mainBlueprint"
            :mainSelectedBlueprint="mainSelectedBlueprint"
            :cardView="cardView"
            :otherBlueprint="otherBlueprint"
            :otherBlueprints="otherBlueprints"
            :otherSelectedBlueprintId="otherSelectedBlueprintId"
            :defaultSettings="defaultSettings"
            :fieldConditionsDataAttributes="fieldConditionsDataAttributes"
            @setFieldConditionsData="setFieldConditionsData"
            @closeFieldConditionsModal="closeFieldConditionsModal"
          ></FieldConditions>
        </div>
      </a-modal>
    </template>
  </div>
</template>

<script>
import { FormOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { defineAsyncComponent } from 'vue';

const FieldConditions = defineAsyncComponent(
  () => import('@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/fieldConditions.vue')
);

export default {
  components: { FieldConditions, FormOutlined, PlusOutlined },
  props: [
    'value',
    'modelValue',
    'defaultSettings',
    'optionChangedData',
    'mainBlueprint',
    'blueprints',
    'selectedBlueprint',
    'parentReferenceFields',
    'conditionsAcceptFieldValue',
    'toFieldFromOtherBlueprint',
    'justReferencesAndFieldType',
    'justReferenceFields',
    'justReferenceAndIds',
    'justSingleReferenceFields',
    'autoFieldConditions',
    'cardView',
    'otherBlueprint',
    'hideIndex',
    'addTextToFirstSelector',
    'forceShowId',
    'keyLimit',
    'noCondition',
  ],
  emits: ['toField', 'update:modelValue', 'reloadField', 'updateField', 'fieldType'],
  data() {
    return {
      loading: true,
      referenceField: [],

      otherBlueprints: [],

      referenceFieldsKey: 1,
      fieldConditionsDataAttributesNotSaved: [],
      fieldConditionsDataAttributes: [],

      autoFieldConditionsData: [],
      fieldConditionsData: [],
      fieldConditionsDataNotSaved: [],

      fieldConditionsModal: false,
      fieldConditionsBlueprint: null,

      otherSelectedBlueprintId: null,
      otherSelectedBlueprintJoinFieldId: null,
      selectedFiedId: null,
      selectedFiedIdKey: null,
      getFromInstance: null,
      mountedFinish: false,
      modalItemKey: null,
      localModelValue: null,
    };
  },
  async mounted() {
    if (this.value) {
      this.localModelValue = this.value;
    } else {
      this.localModelValue = this.modelValue;
    }
    if (!this?.parentReferenceFields?.length) {
      let lastField = null;
      if (this.localModelValue) {
        lastField = [...this.localModelValue].pop();
        this.$emit('toField', lastField ?? null, 0);
      }
      this.otherBlueprints = this.blueprints.filter(item => {
        if (item.fields) {
          return item.fields.find(field => {
            const blueprintId = field.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId;
            return this.selectedBlueprint._id === blueprintId;
          });
        }
        return false;
      });

      if (this.toFieldFromOtherBlueprint && lastField) {
        this.otherSelectedBlueprintId = lastField.blueprintId;
        this.otherSelectedBlueprintJoinFieldId = lastField.joinFieldId;
      }
      this.referenceField.push({
        blueprintId: this.mainSelectedBlueprint._id,
        blueprintName: this.mainSelectedBlueprint.name,
        fields: this.mainSelectedBlueprint?.fields ?? this.blueprints.find(item => item._id === this.mainSelectedBlueprint._id).fields,
        otherFieldsFromOtherBlueprints: this.otherFieldsFromOtherBlueprints2(this.mainSelectedBlueprint._id),
      });
      if (this.localModelValue && Array.isArray(this.localModelValue)) {
        this.localModelValue.forEach(data => {
          const field = (this.blueprints.find(item => item._id === data.blueprintId)?.fields ?? []).find(item => item._id === data.fieldId);

          const type = field?.structure?.elementStructure?.type ?? field?.structure?.type;

          if (field && type === 'reference') {
            const blueprintId = field.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId;
            const blueprint = this.blueprints.find(bp => bp._id === blueprintId);

            this.referenceField.push({
              blueprintId: blueprint._id,
              blueprintName: blueprint.name,
              fields: blueprint.fields,
              otherFieldsFromOtherBlueprints: this.otherFieldsFromOtherBlueprints2(blueprint._id),
            });
          }
        });
      }
    }
    const value = [];
    if (this?.parentReferenceFields?.length) {
      await this.parentReferenceFields.reduce((promise, data) => {
        return promise.then(async () => {
          if (data?.autoFieldConditionsData?.length) {
            this.autoFieldConditionsData = data.autoFieldConditionsData;
          }
          if (data.fieldId.startsWith('other_')) {
            const blueprint = this.blueprints.find(bp => bp._id === data.blueprintId);

            this.referenceField.push({
              blueprintId: blueprint._id,
              blueprintName: blueprint.name,
              fields: blueprint.fields,
              fieldId: data.fieldId,
              otherFieldsFromOtherBlueprints: this.otherFieldsFromOtherBlueprints2(this.mainBlueprint._id.toString()),
            });
            value.push(await this.selectField(data.fieldId, this.referenceField[this.referenceField.length - 1], this.referenceField.length - 1));
          } else {
            const field = this.blueprints.find(item => item._id === data.blueprintId).fields.find(item => item._id === data.fieldId);
            const type = field?.structure?.elementStructure?.type ?? field?.structure?.type;
            if (data.fieldConditionsDataAttributes.length) {
              this.fieldConditionsDataAttributes = data.fieldConditionsDataAttributes;
            }

            if (field && type === 'reference') {
              const blueprint = this.blueprints.find(bp => bp._id === data.blueprintId);
              if (this.referenceField.length === 0) {
                this.referenceField.push({
                  blueprintId: blueprint._id,
                  blueprintName: blueprint.name,
                  fields: blueprint.fields,
                  fieldId: data.fieldId,
                  otherFieldsFromOtherBlueprints: this.otherFieldsFromOtherBlueprints2(
                    field.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId
                  ),
                });
              }
              value.push(await this.selectField(data.fieldId, this.referenceField[this.referenceField.length - 1], this.referenceField.length - 1));
            } else {
              if (this.referenceField.length === 0) {
                const blueprintId = field ? field.blueprintId : data.blueprintId;
                const blueprint = this.blueprints.find(bp => bp._id === blueprintId);
                this.referenceField.push({
                  blueprintId: blueprint._id,
                  blueprintName: blueprint.name,
                  fields: blueprint.fields,
                  fieldId: data.fieldId,
                  otherFieldsFromOtherBlueprints: this.otherFieldsFromOtherBlueprints2(blueprint._id),
                });
              }
              this.referenceField[this.referenceField.length - 1].fieldId = data.fieldId;
              value.push(
                value,
                await this.selectField(data.fieldId, this.referenceField[this.referenceField.length - 1], this.referenceField.length - 1)
              );
            }
          }
        });
      }, Promise.resolve());
    }
    this.mountedFinish = true;
  },
  computed: {
    otherFieldsFromOtherBlueprints() {
      const blueprints = [];
      this.blueprints.forEach(blueprint => {
        const fields = blueprint.fields.filter(field => {
          const type = field?.structure?.elementStructure?.type ?? field?.structure?.type;
          if (type === 'reference') {
            const blueprintId = field.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId;
            return blueprintId.toString() === this.selectedBlueprint._id.toString();
          }
          return false;
        });
        if (fields.length) {
          blueprints.push({
            ...blueprint,
            fields,
          });
        }
      });
      return blueprints;
    },
    mainSelectedBlueprint() {
      if (this.toFieldFromOtherBlueprint && this.otherSelectedBlueprintId) {
        return this.blueprints.find(item => item._id === this.otherSelectedBlueprintId);
      }
      return this.selectedBlueprint;
    },
    selectedFromOtherBlueprintValue() {
      const item = (this.localModelValue && this.localModelValue[0]) ?? null;
      if (item?.blueprintId) {
        if (!this.otherBlueprints.some(data => data._id === item.blueprintId)) {
          return undefined;
        }
      }
      return item?.blueprintId ?? undefined;
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler(value) {
        this.localModelValue = value;
      },
    },
    toFieldFromOtherBlueprint() {
      this.referenceField = [];
      setTimeout(() => {
        this.referenceField = [
          {
            blueprintId: this.mainSelectedBlueprint._id,
            blueprintName: this.mainSelectedBlueprint.name,
            fields: this.mainSelectedBlueprint.fields,
            otherFieldsFromOtherBlueprints: this.otherFieldsFromOtherBlueprints2(this.mainSelectedBlueprint._id),
          },
        ];
      }, 10);

      this.$emit('update:modelValue', []);
      this.$emit('toField', null, this.referenceField.length - 1);
    },
  },
  methods: {
    openFieldConditionsModal(blueprint, key) {
      this.modalItemKey = key;
      this.fieldConditionsModal = true;
      this.fieldConditionsBlueprint = blueprint;

      const item = this.fieldConditionsDataAttributes.find(el => el.blueprintId === blueprint.blueprintId);
      if ((item?.conditions ?? []).length) {
        this.fieldConditionsData = item.conditions;
      } else {
        this.fieldConditionsData = [];
      }
    },
    closeFieldConditionsModal() {
      this.fieldConditionsModal = false;
      this.fieldConditionsBlueprint = null;
      this.modalItemKey = null;
    },
    closeFieldConditionsModalAndSave() {
      if (!this.fieldConditionsDataAttributes.find(item => item.blueprintId === this.fieldConditionsBlueprint.blueprintId)) {
        this.fieldConditionsDataAttributes.push({
          blueprintId: this.fieldConditionsBlueprint.blueprintId,
          conditions: this.fieldConditionsDataNotSaved,
        });
      }

      this.selectField(this.referenceField[this.modalItemKey].fieldId, this.referenceField[this.modalItemKey], this.modalItemKey, true);
      this.closeFieldConditionsModal();
    },
    autoFieldConditionsCalculation(itemField, fieldId, index) {
      let data = [];
      if (this.mainBlueprint) {
        const split = (fieldId ?? '').split('-');
        const extractFieldId = split[split.length - 1];

        let filed = itemField.fields.find(f => f._id.toString() === extractFieldId.toString());
        if (!filed) {
          filed = itemField.otherFieldsFromOtherBlueprints.find(f => f._id.toString() === extractFieldId.toString());
        }
        const fieldBlueprintId = filed?.structure?.elementStructure?.ruleset?.blueprintId ?? filed?.structure?.ruleset?.blueprintId ?? null;

        const fields1 = (itemField.fields ?? [])
          .filter(item => {
            // eliminate self reference by default
            if (item.blueprintId.toString() !== this.mainBlueprint._id.toString()) {
              const bt = item?.structure?.elementStructure?.ruleset?.blueprintId ?? item.structure.ruleset.blueprintId;
              if (bt) {
                return bt.toString() === this.mainBlueprint._id.toString();
              }
            }
            return false;
          })
          .map(item => {
            return { ...item, mainBlueprint: true };
          });

        let fields2 = [];
        if (fieldBlueprintId && this.mainBlueprint !== fieldBlueprintId) {
          fields2 = (this.mainBlueprint?.fields ?? [] ?? []).filter(item => {
            const bt = item?.structure?.elementStructure?.ruleset?.blueprintId ?? item.structure.ruleset.blueprintId;
            if (bt) {
              return bt.toString() === fieldBlueprintId.toString();
            }
            return false;
          });
        }

        data = [...fields1, ...fields2];
        const autoFieldConditionsData = this.autoFieldConditionsData.find(f => f.blueprintId === itemField.blueprintId);
        if (autoFieldConditionsData) {
          this.autoFieldConditionsData = this.autoFieldConditionsData.filter(f => f.blueprintId !== itemField.blueprintId);
        }

        if (data.length) {
          this.autoFieldConditionsData = [
            ...this.autoFieldConditionsData,
            {
              index,
              blueprintId: itemField.blueprintId,
              conditions: data.map(item => {
                return {
                  label: item?.label,
                  description: `${itemField?.blueprintName} > ${filed?.label} field links to ${this.mainBlueprint?.name} > ${item?.label} field`,
                  fieldId: (item?.mainBlueprint ?? false) === true ? item._id.toString() : extractFieldId,
                  openInstanceFieldId: (item?.mainBlueprint ?? false) === true ? '_id' : item._id.toString(),
                  active: true,
                };
              }),
            },
          ];
        }
      }
      return data;
    },

    async selectField(fieldId, item, key, modeUpdate = false) {
      this.selectedFiedIdKey = key;
      this.selectedFiedId = fieldId;
      let value = this.localModelValue ? [...this.localModelValue] : [];
      if (this.mainBlueprint) {
        let prevItem = this.referenceField[key - 1] ?? null;
        if (!prevItem) {
          prevItem = {
            blueprintId: this.mainBlueprint._id,
            blueprintName: this.mainBlueprint.name,
            fields: this.mainBlueprint.fields,
          };
        }
      }
      if (this.mountedFinish) {
        this.autoFieldConditionsCalculation(item, fieldId, key);
      }

      this.referenceField = this.referenceField.filter((f, index) => index < key + 1);
      if (key === 0) {
        value = [];
      } else {
        value = value.filter((f, index) => index < key);
      }
      if (!fieldId) {
        this.autoFieldConditionsData = this.autoFieldConditionsData.filter(f => f.index < key);
      }
      // remove

      let updateValue = {};
      if (key === 0 && this.otherSelectedBlueprintId) {
        updateValue.joinFieldId = this.otherSelectedBlueprintJoinFieldId;
      }

      let field;
      let otherBlueprintId;
      let otherFieldId;
      if (fieldId) {
        if (fieldId.startsWith('other_')) {
          const fieldIdSplit = fieldId.replace('other_', '').split('-');
          [otherBlueprintId, otherFieldId] = fieldIdSplit;

          field = this.otherFieldsFromOtherBlueprintsByFieldId(otherFieldId, otherBlueprintId);
        } else {
          field = this.referenceField[key].fields.find(el => el._id === fieldId);
        }

        const type = field?.structure?.elementStructure?.type ?? field?.structure?.type;
        if (field && type === 'reference') {
          const blueprintId = otherBlueprintId ?? field.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId;
          const blueprint = this.blueprints.find(bp => bp._id === blueprintId);

          this.referenceField.push({
            blueprintId: blueprint._id,
            blueprintName: blueprint.name,
            fields: blueprint.fields,
            otherFieldsFromOtherBlueprints: this.otherFieldsFromOtherBlueprints2(blueprint._id),
          });

          updateValue = {
            ...updateValue,
            blueprintId: item.blueprintId,
            fieldId,
            fieldName: (item?.fields ?? []).find(f => f._id.toString() === fieldId.toString())?.label ?? fieldId,
            autoFieldConditionsData: this.autoFieldConditionsData,
            fieldConditionsDataAttributes: this.fieldConditionsDataAttributes ?? [],
            type: 'reference',
          };

          value.push(updateValue);
        } else {
          let fieldType = type;
          let dateFormat = null;
          if (fieldId === '_id') {
            fieldType = 'number';
          } else if (fieldId === '_ownerId') {
            fieldType = 'string';
          } else if (fieldId === '_createdAt') {
            fieldType = 'date';
            dateFormat =
              field?.structure?.elementStructure?.options?.format ??
              field?.structure?.options?.format ??
              this.defaultSettings?.dateFormat ??
              'DD-MM-YYYY';
          }

          updateValue = {
            ...updateValue,
            blueprintId: item.blueprintId,
            fieldId,
            getFromInstance: this.getFromInstance ?? null,
            fieldName: (item?.fields ?? []).find(f => f._id.toString() === fieldId.toString())?.label ?? fieldId,
            autoFieldConditionsData: this.autoFieldConditionsData,
            fieldConditionsDataAttributes: this.fieldConditionsDataAttributes ?? [],
            type: fieldType,
            dateFormat,
          };

          if (dateFormat) {
            updateValue.dateFormat = dateFormat;
          }

          value.push(updateValue);
        }
      }
      if (modeUpdate) {
        this.$emit('updateField', updateValue, key, this.mountedFinish);
      } else {
        this.$emit('update:modelValue', value);
        this.$emit('toField', updateValue, key, this.mountedFinish);
      }
    },

    selectFieldFromOtherBlueprint(blueprintId) {
      if (blueprintId) {
        const blueprint = this.otherBlueprints.find(item => item._id === blueprintId);
        const field = blueprint.fields.find(f => {
          const bpId = f.structure?.elementStructure?.ruleset?.blueprintId ?? f.structure.ruleset.blueprintId;
          return this.selectedBlueprint._id === bpId;
        });
        this.otherSelectedBlueprintJoinFieldId = field._id;
      } else {
        this.otherSelectedBlueprintJoinFieldId = null;
      }

      this.otherSelectedBlueprintId = blueprintId;

      this.referenceField = [];
      setTimeout(() => {
        this.referenceField = [
          {
            blueprintId: this.mainSelectedBlueprint._id,
            blueprintName: this.mainSelectedBlueprint.name,
            fields: this.mainSelectedBlueprint.fields,
            otherFieldsFromOtherBlueprints: this.otherFieldsFromOtherBlueprints2(this.mainSelectedBlueprint._id),
          },
        ];
      }, 10);

      this.$emit('update:modelValue', []);
      this.$emit('fieldType', null);
    },

    selectedFromBlueprintValue(blueprintId) {
      const item = (this.localModelValue ?? []).find(el => el.blueprintId === blueprintId) ?? undefined;
      if (item?.fieldId) {
        return item.fieldId;
      }
      return item;
    },

    otherFieldsFromOtherBlueprintsByFieldId(fieldId, blueprintId = null) {
      if (blueprintId) {
        const blueprint = this.blueprints.find(item => item._id === blueprintId);
        if (blueprint) {
          return blueprint.fields.find(item => item._id.toString() === fieldId.toString());
        }
      } else if (Array.isArray(this.blueprints)) {
        const blueprint = this.blueprints.find(bp => bp.fields.some(item => item._id === fieldId));
        if (blueprint) {
          return blueprint.fields.find(item => item._id === fieldId);
        }
      }
      return null;
    },

    otherFieldsFromOtherBlueprints2(blueprintId) {
      const blueprints = [];
      this.blueprints.forEach(blueprint => {
        const fields = blueprint.fields.filter(field => {
          const type = field?.structure?.elementStructure?.type ?? field?.structure?.type;
          if (type === 'reference') {
            const refBlueprintId = field.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId;
            return refBlueprintId.toString() === blueprintId.toString();
          }
          return false;
        });
        if (fields.length) {
          blueprints.push({
            ...blueprint,
            fields,
          });
        }
      });

      return blueprints;
    },
    setFieldConditionsData(data) {
      this.fieldConditionsDataNotSaved = data;
    },
    filterOption(input, option) {
      if (option.label) {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return false;
    },
  },
};
</script>

<style scoped></style>
