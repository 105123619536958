<template>
  <div>
    <a-dropdown-button @click="onClickAccount" style="margin-top: 5px" class="dropdown-top">
      <div class="hide-mobile-item" style="background-color: #ddd; color: black; font-weight: bold; border-radius: 100%; width: 25px; height: 25px">
        {{ userfirstLetters }}
      </div>
      <div class="show-mobile-item" style="background-color: #ddd; color: black; font-weight: bold; border-radius: 100%; width: 25px; height: 25px">
        {{ userfirstLetters }}
      </div>
      <div class="hide-mobile-item" style="margin-left: 5px">{{ subtenantName }}</div>
      <template #overlay>
        <a-menu style="width: 200px">
          <div style="margin-left: 10px" class="show-mobile-item"><AppstoreOutlined /> {{ subtenantName }}</div>
          <a-menu-divider />
          <a-menu-item key="1" @click="onClickAccount">
            <UserOutlined />
            <span>Account</span>
          </a-menu-item>

          <a-menu-divider />
          <router-link :to="{ name: 'TenantsList' }" custom v-slot="{ navigate }">
            <a-menu-item key="2" @click="navigate">
              <AppstoreOutlined />
              <span>Tenants</span>
            </a-menu-item>
          </router-link>

          <div v-if="!user?.isSuperAdmin">
            <a-menu-divider />
            <div v-for="tenant in tenants" :key="tenant._id">
              <template v-if="tenant.applications">
                <div v-for="application in tenant.applications" :key="application._id">
                  <span style="margin-left: 10px"> {{ application.name }} </span>
                  <template v-if="application.subtenants">
                    <div v-for="subtenant in application.subtenants" :key="subtenant._id">
                      <a :href="`/${tenant.slug}/${application.slug}/${subtenant.slug}/collections`">
                        <a-menu-item style="padding: 0px 20px">
                          <span> {{ subtenant.name }} </span>
                        </a-menu-item>
                      </a>
                    </div>
                  </template>
                  <a-menu-divider />
                </div>
              </template>
            </div>
          </div>
          <a-menu-divider />
          <router-link :to="{ name: 'UserFeedback' }" custom v-slot="{ navigate }">
            <a-menu-item v-if="user?.isSuperAdmin" key="4" @click="navigate">
              <AppstoreOutlined />
              <span>User Feedback</span>
            </a-menu-item>
          </router-link>

          <a-menu-divider />

          <a-menu-item key="3" @click="onClickLogout">
            <LogoutOutlined />
            Logout
          </a-menu-item>

          <a-menu-divider />

          <a-menu-item key="4" v-if="subtenantPermanentModal">
            Show modal:
            <a-switch
              v-model:checked="localShowPermanentModal"
              @change="showHidePermanentModal"
              checked-children="Enable"
              un-checked-children="Disable"
            >
            </a-switch>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown-button>
  </div>
</template>

<script>
import { UserActions, UserGetters } from '@userManagement/user.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';
import { UserOutlined, AppstoreOutlined, LogoutOutlined } from '@ant-design/icons-vue';
import { SlugGetters } from '@/slug.store';
import router from '@/router';

export default {
  name: 'AccountComponent',
  components: {
    UserOutlined,
    AppstoreOutlined,
    LogoutOutlined,
  },
  props: ['user', 'appUrl', 'subtenantUrl', 'subtenantPermanentModal', 'subtenantName'],

  data() {
    return {};
  },
  created() {
    this.localShowPermanentModal = this.showPermanentModal;
  },
  watch: {
    showPermanentModal() {
      this.localShowPermanentModal = this.showPermanentModal;
    },
  },
  computed: {
    tenants: TenantsGetters.getTenants,
    subtenantSlug: SlugGetters.getSubtenantSlug,
    userfirstLetters() {
      return this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
    },
    showPermanentModal() {
      const user = UserGetters.getUser();
      if (user?.permanentModal?.[this.subtenantData._id]) {
        return user?.permanentModal[this.subtenantData._id];
      }
      return false;
    },
    subtenantData() {
      let subtenantData = [];
      if (this.subtenantSlug) {
        subtenantData = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
      }

      return subtenantData;
    },
  },
  methods: {
    async onClickLogout() {
      await UserActions.logout();
      // window.location.href = '/';
    },
    onClickAccount() {
      const tenantSlug = SlugGetters.getTenantSlug();
      const appSlug = SlugGetters.getAppSlug();
      const subtenantSlug = SlugGetters.getSubtenantSlug();

      router.push({
        name: 'Account',
        params: { tenantSlug, appSlug, subtenantSlug },
      });
    },
    showHidePermanentModal(value) {
      this.localShowPermanentModal = value;
      UserActions.updateUserPermanentModal(value, this.subtenantData._id);
    },
  },
};
</script>

<style scoped>
.dropdown-top .ant-btn {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
}
</style>
