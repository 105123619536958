import axios from 'axios';
import { SlugGetters } from '@/slug.store';
import LogRocket from 'logrocket';

const getEmailByResetToken = async resetToken => {
  const response = await axios.get(`/user-management/reset-token-email/${resetToken}`);
  return response.data;
};

const getUserProfile = async userId => {
  const response = await axios.get(`/user-management/user-profile/${userId}`);
  return response.data;
};

const patchUserProfile = async ({ userId, firstName, lastName, country, city, address, linkedin, facebook }) => {
  const response = await axios.patch(`/user-management/user-profile/${userId}`, {
    firstName,
    lastName,
    country,
    city,
    address,
    linkedin,
    facebook,
  });
  return response.data;
};

const patchUserPermanentModal = async (subtenantId, userId, permanentModal) => {
  const response = await axios.patch(`/user-management/permanent-modal/${userId}`, {
    subtenantId,
    permanentModal,
  });
  return response.data;
};

const getTenantAdmins = async tenantId => {
  if (!tenantId) {
    return [];
  }
  const response = await axios.get(`/user-management/tenant-admins/${tenantId}`);
  return response.data;
};

const getSubtenantManagers = async subtenantId => {
  if (!subtenantId) {
    return [];
  }
  const response = await axios.get(`/user-management/subtenant-managers/${subtenantId}`);
  return response.data;
};

const getSubtenantUsers = async subtenantId => {
  if (!subtenantId) {
    return [];
  }
  const response = await axios.get(`/user-management/subtenant-users/${subtenantId}`);
  return response.data;
};

const getSubtenantUsersBySlugs = async (searchTerm = '', userId = '') => {
  const tenantSlug = SlugGetters.getTenantSlug();
  const appSlug = SlugGetters.getAppSlug();
  const subtenantSlug = SlugGetters.getSubtenantSlug();

  const response = await axios.get(
    `/user-management/subtenant-users-slugs/${tenantSlug}/${appSlug}/${subtenantSlug}?searchTerm=${searchTerm}&userId=${userId}`
  );
  return response.data;
};

const createUser = async (userId, userType, tenantId, appId, subtenantId, { email, firstName, lastName, password, role }) => {
  const response = await axios.post(`/user-management/register`, {
    userId,
    userInput: {
      email,
      firstName,
      lastName,
      password,
      userType,
      role,
      tenantId,
      appId,
      subtenantId,
    },
  });
  return response.data;
};
const postRegister = async ({ email, firstName, lastName, password, invitationToken }) => {
  const response = await axios.post(`/user-management/register`, {
    userInput: {
      email,
      firstName,
      lastName,
      password,
    },
    invitationToken,
  });
  return response.data;
};

const getFeedback = async () => {
  const response = await axios.get(`/user-management/feedback`);
  return response.data;
};

const getUserFullNameByIdList = async userIdList => {
  const response = await axios.post(`/user-management/user-full-name-list`, {
    userIdList,
  });
  return response.data;
};

const postFeedback = async description => {
  const tenantSlug = SlugGetters.getTenantSlug();
  const appSlug = SlugGetters.getAppSlug();
  const subtenantSlug = SlugGetters.getSubtenantSlug();

  let sessionUrl = '';
  LogRocket.getSessionURL(sessionURL => {
    sessionUrl = sessionURL;
  });

  const response = await axios.post(`/user-management/feedback`, {
    description,
    scope: {
      tenantSlug,
      appSlug,
      subtenantSlug,
      sessionUrl,
    },
  });
  return response.data;
};

const toggleTwoFactorAuth = async (userId, tfaValue, overEmail) => {
  const response = await axios.put(`/user-management/toggle-tfa/${userId}`, {
    tfaValue,
    overEmail,
  });

  return response.data;
};

export default {
  getEmailByResetToken,
  getUserProfile,
  patchUserProfile,
  patchUserPermanentModal,
  getTenantAdmins,
  getSubtenantManagers,
  getSubtenantUsers,
  getSubtenantUsersBySlugs,
  postRegister,
  createUser,
  getFeedback,
  postFeedback,
  toggleTwoFactorAuth,
  getUserFullNameByIdList,
};
