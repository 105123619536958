import { reactive } from 'vue';
import LogRocket from 'logrocket';
import { authApi, userApi } from '@userManagement/api';
import { socket } from '@/socket';
import { notification } from 'ant-design-vue';

import _ from 'lodash';
import { setAxioxAuthorizationHeader } from './auth.helper';

const initialState = {
  user: null,
  userNamesByIdList: [],
};

const state = reactive({ ...initialState });

const Getters = {
  getUser: () => {
    return state.user;
  },
  getUserNamesByIdList: () => {
    return state.userNamesByIdList;
  },
};

const Mutations = {
  SET_USER: user => {
    state.user = user;
  },
  UNSET_USER: () => {
    state.user = null;
  },
  ADD_USERNAMES_TO_LIST: newUsernamesByIdList => {
    state.userNamesByIdList = _.uniqBy([...state.userNamesByIdList, ...newUsernamesByIdList], 'userId');
  },
};

const Actions = {
  async login(email, password) {
    const { tfa, user, accessToken, defaultUrl } = await authApi.postLogin(email, password);

    // Get redirect URL from the query parameters
    const searchUrl = window.location.search;
    const urlParams = new URLSearchParams(searchUrl);
    const redirectUrl = urlParams.get('redirect');

    if (tfa) {
      const router = (await import('@/router')).default;
      await router.push({ name: 'TFAComponent', params: { userId: user._id } });

      if (redirectUrl !== '/') {
        window.location.search = searchUrl;
      }

      return;
    }

    Mutations.SET_USER(user);
    setAxioxAuthorizationHeader(accessToken);
    LogRocket.identify(user._id, {
      name: `${user.firstName}, ${user.lastName}`,
      email: user.email,
    });

    // Session listener for one-session functionality
    await Actions.setupSessionInvalidationListener(user._id);

    // Redirect to the appropriate page
    window.location.href = `${window.location.origin}${redirectUrl && redirectUrl !== '/' ? decodeURI(redirectUrl) : defaultUrl}`;
  },
  async tfa(userId, token) {
    const { user, accessToken, defaultUrl } = await authApi.postTokenLogin(userId, token);

    // Get redirect URL from the query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect');

    Mutations.SET_USER(user);
    setAxioxAuthorizationHeader(accessToken);
    LogRocket.identify(user._id, {
      name: `${user.firstName}, ${user.lastName}`,
      email: user.email,
    });

    // Session listener for one-session functionality
    await Actions.setupSessionInvalidationListener(user._id);

    // Redirect to the appropriate page
    window.location.href = `${window.location.origin}${redirectUrl && redirectUrl !== '/' ? decodeURI(redirectUrl) : defaultUrl}`;
  },
  async silentLogin(loginPage = false) {
    const { user, accessToken } = await authApi.putLoginRefresh();

    Mutations.SET_USER(user);
    setAxioxAuthorizationHeader(accessToken);
    LogRocket.identify(user._id, {
      name: `${user.firstName}, ${user.lastName}`,
      email: user.email,
    });

    // Session listener for one-session functionality
    await Actions.setupSessionInvalidationListener(user._id);

    if (loginPage) {
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get('redirect');

      // Redirect to the appropriate page
      window.location.href = `${window.location.origin}${redirectUrl ? decodeURI(redirectUrl) : '/tenants'}`;
    }

    return { accessToken };
  },
  async logout() {
    const user = Getters.getUser();
    if (socket) {
      // Remove existing listener for this user to avoid duplicates
      socket.off(`session_invalidated:${user._id}`);
    }

    await authApi.deleteLogout();
    Mutations.UNSET_USER();

    // Lazy import router (avoid circular dependency) and redirect to login
    // eslint-disable-next-line import/no-cycle
    const router = (await import('@/router')).default;
    await router.push({ name: 'LoginComponent' });
  },

  async setupSessionInvalidationListener(userId) {
    if (socket) {
      // Remove existing listener for this user to avoid duplicates
      socket.off(`session_invalidated:${userId}`);
      // Add the listener for session invalidation

      const currentRoute = window.location.pathname + window.location.search + window.location.hash;
      const redirectUrl = encodeURIComponent(currentRoute);

      socket.on(`session_invalidated:${userId}`, () => {
        notification.warning({
          message: 'Session Ended',
          description: 'You have been logged out because your account was logged in elsewhere.',
          duration: 0,
        });
        window.location.search = `?redirect=${redirectUrl}`;
        Actions.logout();
      });
    }
  },

  async updateUserPermanentModal(showHide, subtenantId) {
    const user = Getters.getUser();
    const userData = await userApi.patchUserPermanentModal(subtenantId, user._id, showHide);
    Mutations.SET_USER(userData);
  },

  async fetchUserFullNameByIdList(userIdList) {
    const response = await userApi.getUserFullNameByIdList(userIdList);
    Mutations.ADD_USERNAMES_TO_LIST(response);
  },
};

export const UserGetters = Getters;
export const UserMutations = Mutations;
export const UserActions = Actions;
