<template>
  <div class="main-app-content" v-if="localLayout">
    <template v-if="localLayout.leftColumn.length">
      <div>
        <div v-for="menu of localLayout.leftColumn" :key="menu._id">
          <RecursiveMenu
            position="l"
            v-if="menu.active && (menu.subtenantId.includes(tenantData.subtenant._id) || menu.allSubtenants)"
            :depth="0"
            :list="menu.menuBuilder"
            @changeWidth="changeWidth"
            style="padding-bottom: 20px"
          />
        </div>
      </div>
    </template>
    <div class="content-container" ref="contentContainer" :style="`width: ${containerWidth}`" :class="centerColumnSize">
      <template v-for="menu of localLayout.centerColumn" :key="menu._id">
        <template v-if="menu._id === '_content'">
          <TemplateDataSystemBuilder :menuItem="menuItem" v-if="menuItem && menuItem.templateId && menuItem.active" />
          <PermanentModal
            v-if="showPermanentModal && menuItemPermanentModal"
            :menuItemPermanentModal="menuItemPermanentModal"
            :showPermanentModal="showPermanentModal"
          />
        </template>
        <template v-else>
          <RecursiveMenu
            position="c"
            v-if="menu.active && (menu.subtenantId.includes(tenantData.subtenant._id) || menu.allSubtenants)"
            :depth="0"
            :list="menu.menuBuilder"
            @changeWidth="changeWidth"
          />
        </template>
      </template>
    </div>
    <div v-if="localLayout.rightColumn.length">
      <div v-for="menu of localLayout.rightColumn" :key="menu._id">
        <RecursiveMenu
          position="r"
          v-if="menu.active && (menu.subtenantId.includes(tenantData.subtenant._id) || menu.allSubtenants)"
          :depth="0"
          :list="menu.menuBuilder"
          @changeWidth="changeWidth"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateDataSystemBuilder from '@/apps/templateDataSystem/views/Builder/TemplateDataSystemBuilder.vue';
import PermanentModal from '@/apps/templateDataSystem/views/Builder/PermanentModal.vue';

import { MenuLayoutGetters, MenuLayoutActions } from '@templateDataSystem/shared/menuLayout.store';
import { findRecursive } from '@/core/utils/array-manipulation';

import { TenantsGetters } from '@tenants/shared/tenants.store';
import { UserRolesGetters } from '@roleManagement/shared/role.subtenant.store';
import { UserGetters } from '@userManagement/user.store';
import { SlugGetters } from '@/slug.store';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import RecursiveMenu from './RecursiveMenu.vue';

export default {
  name: 'TemplateLayoutBuilder',
  props: [],
  components: {
    RecursiveMenu,
    TemplateDataSystemBuilder,
    PermanentModal,
  },
  data() {
    return {
      localLayout: null,
      menuItem: null,
      menuItemId: this.$route.params.menuItemId ?? null,
      isMobile: false,
      containerWidth: '100%',
      menuItemPermanentModal: null,
    };
  },
  computed: {
    layout: MenuLayoutGetters.getMenuLayout,
    tenantData: TenantsGetters.getTenantAppSubtenant,
    subtenantSlug: SlugGetters.getSubtenantSlug,
    showPermanentModal() {
      const user = UserGetters.getUser();
      return user?.permanentModal?.[this.subtenantData._id] || false;
    },
    subtenantData() {
      let subtenantData = [];
      if (this.subtenantSlug) {
        subtenantData = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
      }

      return subtenantData;
    },
    centerColumnSize() {
      let col = 12;
      if ((this.localLayout?.leftColumn ?? []).length) {
        col -= 3;
      }
      if ((this.localLayout?.rightColumn ?? []).length) {
        col -= 3;
      }
      return `${col}`;
      // return `col-md-${col}`;
    },
  },
  async created() {
    this.isMobile = window.screen.width < 600;
    await MenuLayoutActions.init();
    await this.getMenuItem();
    await this.fetchUserRoles();
    // this.appTemplates = await templateManagement.getAllTemplates();

    this.localLayout = {
      ...this.layout,
    };
    this.localLayout.centerColumn.splice(this.localLayout.centerContentPosition, 0, { name: 'CONTENT', id: '_content', _id: '_content' });

    await this.findStartPage();
    this.containerWidth = `${this.$refs.contentContainer.clientWidth}px`;
  },
  watch: {
    '$route.params.menuItemId': async function (value) {
      this.menuItemId = value;
      await this.getMenuItem();
      await this.findStartPage();
    } /* ,
    userRoles() {
      if (this.userRoles.length) {
        //this.setMenuItemsVisibility();
      }
    } */,
  },

  methods: {
    async fetchUserRoles() {
      this.userRoles = UserRolesGetters.getUserRoles();
    },
    async findStartPage() {
      if (!this.menuItemId) {
        let redirected = await this.findStartPageColumn(this.layout.leftColumn, 'l');
        if (!redirected) {
          redirected = await this.findStartPageColumn(this.layout.centerColumn, 'c');
          if (!redirected) {
            await this.findStartPageColumn(this.layout.rightColumn, 'r');
          }
        }
      }
    },
    changeWidth(opened) {
      const el = this;
      el.containerWidth = `calc(${el.$refs.contentContainer.clientWidth}px ${opened ? '+' : '-'} 255px)`;
    },
    async findStartPageColumn(data, position) {
      let firstPageIdFromMenu = null;
      let menuStartPage = null;
      for (let i = 0; i < data.length; i += 1) {
        // find first page with userRoles
        const menu = data[i];
        // check for set default start page in startMenus
        if (Array.isArray(menu.startMenus) && menu.startMenus.length && this.userRoles.length) {
          let localMenuStartPage = null;

          this.userRoles.some(role => {
            localMenuStartPage = menu.startMenus.find(sm => sm.userRoles.includes(role._id));
            return !!localMenuStartPage;
          });

          menuStartPage = localMenuStartPage;
          if (menuStartPage) {
            // set page and exit
            router.push({
              name: 'templateDataSystemBuilder',
              params: { menuItemId: position + menuStartPage.startMenuId },
            });
            break;
          }
          // check for first page with no role set
          if (menu?.startMenus?.length) {
            firstPageIdFromMenu = menu.startMenus.find(stMenu => stMenu?.userRoles?.length === 0)?.startMenuId ?? firstPageIdFromMenu;
          }
        }
        // search for first menu with a template
        if (Array.isArray(menu?.menuBuilder) && !firstPageIdFromMenu) {
          // first menu with a template
          for (let j = 0; j < menu?.menuBuilder?.length; j += 1) {
            const menuWithTemplateId = this.findFirstNonNullTemplateId(menu.menuBuilder[j]);
            if (menuWithTemplateId) {
              firstPageIdFromMenu = menuWithTemplateId;
              break;
            }
          }
        }
      }
      // set route for no direct default page
      if (firstPageIdFromMenu && !menuStartPage) {
        router.push({
          name: 'templateDataSystemBuilder',
          params: { menuItemId: position + firstPageIdFromMenu },
        });
      }
    },
    async getMenuItem() {
      this.menuItem = null;
      this.menuItemPermanentModal = null;
      let items = [];
      if (this.menuItemId) {
        const menuItemId = (this.menuItemId ?? '').substring(1);
        const position = (this.menuItemId ?? '').charAt(0);
        if (position === 'l') {
          items = this.layout.leftColumn;
        } else if (position === 'c') {
          items = this.layout.centerColumn;
        } else if (position === 'r') {
          items = this.layout.rightColumn;
        }
        if (!items.length) {
          this.menuItem = null;
          this.menuItemPermanentModal = null;
        }

        for (let i = 0; i < items.length; i += 1) {
          const menu = items[i];
          if (!this.menuItem && (menu.subtenantId.includes(this.tenantData.subtenant._id) || menu.allSubtenants)) {
            this.menuItem = findRecursive(menu?.menuBuilder ?? [], menuItemId);
            if (this.menuItem) {
              break; // Exit the loop as soon as the condition is met
            }
          }
        }
        if (this.showPermanentModal && this.subtenantData.permanentModalTemplateId) {
          for (let i = 0; i < items.length; i += 1) {
            const menu = items[i];
            if (!this.menuItemPermanentModal && (menu.subtenantId.includes(this.tenantData.subtenant._id) || menu.allSubtenants)) {
              this.menuItemPermanentModal = findRecursive(menu?.menuBuilder ?? [], this.subtenantData.permanentModalTemplateId, 'templateId');
              if (this.menuItemPermanentModal) {
                break; // Exit the loop as soon as the condition is met
              }
            }
          }
        }
      } else {
        this.menuItem = null;
        this.menuItemPermanentModal = null;
      }
    },
    findFirstNonNullTemplateId(obj) {
      // Check if the current object's templateId is not null
      if (obj.templateId !== null) {
        return obj._id;
      }

      // If there are children, use some to find the first non-null templateId
      if (obj.children && obj.children.length > 0) {
        let result = null;

        obj.children.some(child => {
          result = this.findFirstNonNullTemplateId(child);
          return result !== null; // Stop the iteration if a result is found
        });
        return result;
      }
      // Return null if no object with a non-null templateId is found
      return null;
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .padd-10 {
    padding: 0;
  }
}
</style>
