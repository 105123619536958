<template>
  <h3 v-if="object?.view?.default?.showTitle">
    {{ object?.view?.default?.customTitle?.length ? object?.view?.default?.customTitle : blueprint?.name }}
  </h3>
  <div
    class="row"
    style="margin-right: 10px; margin-left: 10px"
    v-if="blueprint && (userRolesWhichCanCreate?.length ?? false) && (object.view.default?.showAddNewButton ?? true)"
  >
    <a-button type="primary" @click="openCloseModal('createNew' + (loadMoreView ? '-loadMoreView' : ''), true)">{{
      (object?.view?.default?.addNewButtonLabel ?? '') !== '' ? object?.view?.default?.addNewButtonLabel : 'Add new'
    }}</a-button>
    <div
      style="margin-bottom: 20px; margin-left: 10px"
      v-if="blueprint && (userRolesWhichCanCreate?.length ?? false) && (object.view.default?.showSearchTable ?? false)"
    >
      <search-table :object="object" :blueprint="blueprint"> </search-table>
    </div>
  </div>
  <a-spin :spinning="contentHtmlLoading" style="width: 100%">
    <RenderHtml v-if="renderedContentHtml" @buttonClicked="renderHtmlButtonClicked" :key="renderHtmlTemplateKey" :content="renderedContentHtml" />
  </a-spin>
  <div class="kanban-container" :class="dragging ? 'dragging' : ''">
    <div class="kanban-board" v-if="kanbanData.length">
      <div v-for="(column, index) in kanbanData.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))" :key="index">
        <a-card
          size="small"
          :title="column.title + ' ' + column.columnsCards.length"
          class="kanban-column"
          :bodyStyle="{
            borderTop: this.object.view?.default?.kanban?.columnTitleFieldId ? '2px solid #FFFF' : '',
            width: object.view?.default?.kanban?.columnsWidth + 'px' ?? '300px',
            height: '100%',
          }"
        >
          <div v-if="this.object.view.default.kanban.columsTotals && column.columnsCards.length" style="margin-bottom: 10px">
            <span v-for="(calculation, totalIndex) in this.object.view.default.kanban.columsTotals" :key="totalIndex">
              <strong>{{ calculation.label }}:</strong>
              {{ calculateFormula(column.columnsCards, calculation) }}
              {{ calculation.unit }}
              <br />
            </span>
          </div>
          <draggable
            v-model="column.columnsCards"
            group="kanbanColumns"
            class="task-list"
            :id="column._id"
            item-key="_id"
            :move="canDrag"
            @end="updateOnColumnChange"
            :animation="150"
            :force-fallback="true"
            :scroll-sensitivity="100"
            :scroll-speed="40"
            drag-class="dragging-item"
            ghost-class="destination-item"
            @start="onDragStart"
          >
            <template #item="{ element }">
              <kanban-cards :object="object" :element="element" @openInstance="openInstance" @fetchInstances="fetchInstances"></kanban-cards>
            </template>
          </draggable>
        </a-card>
        <br />
      </div>
    </div>
  </div>
  <span v-if="!cardsOrderFiledId">Cards order in columns not active !</span>
  <a-spin :spinning="contentHtmlLoading" style="width: 100%">
    <RenderHtml
      v-if="renderedContentHtmlAfter"
      @buttonClicked="renderHtmlButtonClicked"
      :key="renderHtmlTemplateKeyAfter"
      :content="renderedContentHtmlAfter"
    />
  </a-spin>
  <!-- CreateNewInstance -->
  <a-modal
    :footer="false"
    :width="'1200px'"
    :destroyOnClose="true"
    :maskClosable="false"
    :open="isOpenModal(instance ? `details` : 'createNew' + (loadMoreView ? '-loadMoreView' : ''))"
    :title="instance ? 'Edit' : (object?.view?.default?.addNewButtonLabel ?? '') || 'Add new'"
    @cancel="openCloseModal(instance ? `details` : 'createNew' + (loadMoreView ? '-loadMoreView' : ''), false)"
  >
    <CreateEditInstance
      :object="object"
      :filterConditions="filterConditions"
      :showEditButton="object.view.default?.showEditButton ?? true"
      :editButtonLabel="object.view.default?.editButtonLabel ?? ''"
      :showDeleteButton="object.view.default?.showDeleteButton ?? true"
      :deleteButtonLabel="object.view.default?.deleteButtonLabel ?? ''"
      :showFields="object?.view?.itemFields ?? []"
      @closePopup="openCloseModal(instance ? `details` : 'createNew' + (loadMoreView ? '-loadMoreView' : ''), false)"
      :blueprint="blueprint"
      :clearAfterSubmit="true"
      :addNew="!instance"
      :instance="instance"
    />
  </a-modal>

  <a-modal
    :footer="false"
    :width="'1200px'"
    :destroyOnClose="true"
    :maskClosable="false"
    :open="isOpenModal(`view`)"
    :title="'View'"
    @cancel="openCloseModal(`view`, false)"
  >
    <ViewInstance :object="object" :showFields="object.view?.itemFields ?? []" :blueprint="blueprint" :instance="instance" />
  </a-modal>
  <!-- CreateNewInstance -->
</template>
<script>
import * as math from 'mathjs';
import RenderHtml from '@templateDataSystem/views/components/RenderHtml';
import { objectId } from '@/core/utils/array-manipulation';

import { UserGetters } from '@/apps/userManagement/user.store';
import { NewTemplatesGetters, NewTemplatesMutations, NewTemplatesActions } from '@/apps/templateDataSystem/shared/newTemplateDataSystem.store';
import CreateEditInstance from '@/apps/templateDataSystem/views/components/CreateEditInstance.vue';
import draggable from 'vuedraggable';
import ViewInstance from '@/apps/templateDataSystem/views/components/ViewInstanceAsText/ViewInstanceAsText.vue';
import SearchTable from '@/apps/templateDataSystem/views/components/SearchTable.vue';
import _ from 'lodash';
import { TemplateDataSerializer } from '@/apps/templateDataSystem/shared/TemplateDataSerializer';
import KanbanCards from '@/apps/templateDataSystem/views/components/KanbanBoard/KanbanCards.vue';
import { slugify } from '@/core/utils/string-manipulation';
import { SlugGetters } from '@/slug.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';
import { formatNumber } from '@/core/utils/number-manipulation';

export default {
  components: {
    KanbanCards,
    SearchTable,
    ViewInstance,
    CreateEditInstance,
    draggable,
    RenderHtml,
  },
  props: ['object', 'loadMoreView', 'viewType', 'lastDepth'],
  data() {
    return {
      deleteLoading: null,
      kanbanColumnsField: null,
      kanbanColumnsInstances: [],
      instance: null,
      cardsOrderFiledId: this.object.view?.default?.kanban?.cardsOrderFiledId,
      kanbanData: [],
      dragging: false,
      contentHtml: null,
      renderHtmlTemplateKey: objectId(),
      renderHtmlTemplateKeyAfter: objectId(),
    };
  },
  async mounted() {
    this.kanbanColumnsField = this.blueprint.fields.find(field => field._id.toString() === this.object.fieldId);
    await this.fetchInstances(null, null, null, this.kanbanColumnsField.structure.ruleset.blueprintId, true);
    const instances = NewTemplatesGetters.getInstanceList(this.object._id, this.kanbanColumnsField.structure.ruleset.blueprintId);
    this.kanbanColumnsInstances = instances?.instanceList ?? [];
    await this.fetchInstances(null, null, null, this.object.blueprintId);
    this.fetchKanbanData();
  },
  watch: {
    instanceListLoading() {
      this.fetchKanbanData();
    },
  },
  computed: {
    user: UserGetters.getUser,
    renderedContentHtml() {
      return NewTemplatesGetters.getContentHtml(this.object._id, this.object.view.type);
    },
    renderedContentHtmlAfter() {
      return NewTemplatesGetters.getContentHtmlAfter(this.object._id, this.object.view.type);
    },
    contentHtmlLoading() {
      return NewTemplatesGetters.getContentHtmlLoading(this.object._id);
    },
    instanceListLoading() {
      return !!(NewTemplatesGetters.getInstanceListLoading(this.object._id) || NewTemplatesGetters.getCreatedEditedDeletedInstance.status);
    },
    blueprint() {
      return NewTemplatesGetters.getBlueprint(this.object.blueprintId);
    },
    filterConditions() {
      return NewTemplatesGetters.getFilterConditions(this.object._id);
    },
    userRoles: NewTemplatesGetters.getRoles,

    userRolesWhichCanCreate() {
      if (Object.keys(this.userRoles).length) {
        return this.userRoles.filter(role => {
          const permission = role.permissionByBlueprintId[this.object.blueprintId];
          return permission && permission.createAuthority.mode === 'yes';
        });
      }
      return null;
    },

    userRolesWhichCanDelete() {
      if (Object.keys(this.userRoles).length) {
        return this.userRoles.filter(role => {
          const permission = role.permissionByBlueprintId[this.object.blueprintId];
          return permission && permission.deleteAuthority.mode !== 'none';
        });
      }
      return null;
    },
    subtenantData() {
      return TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
    },
  },
  methods: {
    renderHtmlButtonClicked(data) {
      this.rowClick(data);
    },
    fetchKanbanData() {
      const data = [];
      const instances = NewTemplatesGetters.getInstanceList(this.object._id, this.object.blueprintId);
      const cardsInstances = TemplateDataSerializer.instanceListToData(
        instances?.instanceList ?? [],
        this.object,
        this.blueprint,
        this.subtenantData
      );

      if (Array.isArray(this.kanbanColumnsInstances) && this.kanbanColumnsInstances.length > 0) {
        this.kanbanColumnsInstances.forEach(columnInstance => {
          const filteredCards = cardsInstances.filter(cardInstance => cardInstance[`${this.object.fieldId}-original-value`] === columnInstance._id);
          data.push({
            title: columnInstance[this.object.view?.default?.kanban?.columnTitleFieldId],
            order: columnInstance[this.object.view?.default?.kanban?.columnOrderFieldId ?? '_id'],
            _id: columnInstance._id,
            columnsCards:
              filteredCards.sort((a, b) => (a[this.cardsOrderFiledId ?? '_id'] ?? a._id) - (b[this.cardsOrderFiledId ?? '_id'] ?? b._id)) ?? [],
          });
        });
      }
      this.kanbanData = data;
    },
    canDrag({ element }) {
      return element?._ownerPermissions?.canEdit;
    },
    async updateOnColumnChange(event) {
      document.body.classList.remove('dragging');
      this.dragging = false;
      const columnIndex = this.kanbanData.map(e => e._id).indexOf(parseInt(event?.to?.id ?? event?.from?.id, 10));

      await this.updateOrder(columnIndex, event.newIndex, event?.to?.id === event?.from?.id);
    },
    async updateOrder(columnIndex, newIndex, sameColumn) {
      const instancesList = this.kanbanData[columnIndex].columnsCards ?? [];
      const updatePromises = [];
      for (let i = 0; i < instancesList.length; i += 1) {
        let updatedInstanceId = null;

        // Check if the card's order has changed or it's moving between columns
        if (parseInt(instancesList[i][this.cardsOrderFiledId ?? '_id'] ?? '0', 10) !== i || !sameColumn) {
          if (this.cardsOrderFiledId) {
            instancesList[i][this.cardsOrderFiledId ?? '_id'] = i;
          }

          if (i === newIndex && !sameColumn) {
            updatedInstanceId = instancesList[i]._id; // Set updatedInstanceId to the correct _id
            instancesList[i][this.object.fieldId] = this.kanbanData[columnIndex]._id;
          }
          // Pass the updatedInstanceId correctly to updateInstance
          updatePromises.push(this.updateInstance(instancesList[i], instancesList[i]._id.toString(), updatedInstanceId));
        }
      }
      // Wait for all updates to complete
      await Promise.all(updatePromises);
    },
    setInstanceReferences(instanceToUpdate, updatedInstanceId = null, keepfieldId = []) {
      this.blueprint.fields
        .filter(f => (f?.structure?.elementStructure?.type ?? f?.structure?.type) === 'reference')
        .forEach(field => {
          // Only update fields if the current instance is not the updated one
          if ((updatedInstanceId !== instanceToUpdate._id || this.object.fieldId !== field._id) && typeof instanceToUpdate[field._id] !== 'number') {
            instanceToUpdate[field._id] = instanceToUpdate[`${field._id}-original-value`];
          }
        });
      // Clean up fields not in blueprint
      Object.keys(instanceToUpdate).forEach(field => {
        if (!this.blueprint.fields.find(blueprintField => blueprintField._id === field)) {
          if (keepfieldId.indexOf(field) === -1) {
            delete instanceToUpdate[field];
          }
        }
      });
      return instanceToUpdate;
    },

    async updateInstance(instance, instanceId, updatedInstanceId = null) {
      let instanceToUpdate = _.cloneDeep(instance);
      instanceToUpdate = this.setInstanceReferences(instanceToUpdate, updatedInstanceId);

      // used for update
      await NewTemplatesActions.createEditInstance(
        this.object._id,
        this.object.blueprintId,
        instanceId,
        instanceToUpdate,
        this.userRolesWhichCanCreate[0]._id,
        null
      );
    },

    async openCloseModal(name, status, reset = false, instance = null) {
      this.instance = null;
      if (instance) {
        this.instance = (await NewTemplatesActions.fetchInstance(this.object._id, this.object.blueprintId, instance._id)) ?? null;
      }
      NewTemplatesMutations.SET_OPEN_CLOSE_MODAL(`${name}-${this.object._id}`, status, reset);
      if (!status || reset) {
        this.loadDetailsPopupInstance = null;
        this.linkToReferenceInstanceBlueprintId = null;
        this.linkToReferenceInstanceInstanceId = null;
        this.linkToReferenceInstanceBlueprintName = '';
      }
      if (!status && !reset) {
        this.fetchInstances(null, null, null, this.object.blueprintId);
      }
    },
    async fetchInstances(pagination, filters, sorter, blueprintId, noFilters = false) {
      const convertOwnerIdToOwnerDetails = this.object.view?.default?.convertOwnerIdToOwnerDetails ?? false;

      let query = NewTemplatesGetters.getInstanceListQueryParams(this.object._id, blueprintId);
      if (!noFilters) {
        query = {
          convertOwnerIdToOwnerDetails,

          ...query,

          objectId: this.object._id,
          templateId: NewTemplatesGetters.getMainTemplateId(),
          filterConditions: noFilters ? [] : (this.object.view?.filters ?? []),
        };
        if (sorter?.field) {
          query.sortFieldId = sorter.field;
        }
        if (sorter?.order) {
          query.sortOrder = sorter.order;
        }

        if (filters) {
          query.filters = filters;
        }
      } else {
        query = {
          ...query,
          objectId: this.object._id,
          filterConditions: noFilters ? [] : (this.object.view?.filters ?? []),
          templateId: null,
        };
      }
      await NewTemplatesActions.fetchInstanceList(this.object._id, blueprintId, query);
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    isOpenModal(name) {
      return NewTemplatesGetters.getIsOpenModal(`${name}-${this.object._id}`);
    },
    openInstance(name, instance) {
      this.openCloseModal(name, true, false, this.setInstanceReferences(instance, null, ['_id', '_ownerPermissions']));
    },
    onDragStart() {
      this.dragging = true;
    },
    slugify,
    calculateFormula(data, settings) {
      const fields = this.object.view.listFields
        .filter(item => item.type === 'number' || (item?.formatting?.count ?? false) === true)
        .map(item => ({ id: item.id, slug: slugify(item.label) }));
      const sum = fieldId =>
        data.reduce((acc, item) => acc + (item.originalValues && item.originalValues[fieldId] ? Number(item.originalValues[fieldId]) : 0), 0);
      const count = fieldId => data.reduce((acc, item) => (item.originalValues && item.originalValues[fieldId] ? acc + 1 : acc), 0);

      const average = fieldId => {
        const total = sum(fieldId);
        const countItems = count(fieldId);
        return countItems ? total / countItems : 0;
      };

      const max = fieldId =>
        data.reduce((acc, item) => {
          const value = item.originalValues && item.originalValues[fieldId] ? Number(item.originalValues[fieldId]) : 0;
          return value > acc ? value : acc;
        }, -Infinity);

      const min = fieldId =>
        data.reduce((acc, item) => {
          const value = item.originalValues && item.originalValues[fieldId] ? Number(item.originalValues[fieldId]) : 0;
          return value < acc ? value : acc;
        }, Infinity);

      const result = settings.calculation
        .replace(/SUM\((.*?)\)/g, (match, fieldName) => {
          const field = fields.find(f => f.slug === fieldName);
          return field ? sum(field.id) : 0;
        })
        .replace(/COUNT\((.*?)\)/g, (match, fieldName) => {
          const field = fields.find(f => f.slug === fieldName);
          return field ? count(field.id) : 0;
        })
        .replace(/AVERAGE\((.*?)\)/g, (match, fieldName) => {
          const field = fields.find(f => f.slug === fieldName);
          return field ? average(field.id) : 0;
        })
        .replace(/MAX\((.*?)\)/g, (match, fieldName) => {
          const field = fields.find(f => f.slug === fieldName);
          return field ? max(field.id) : 0;
        })
        .replace(/MIN\((.*?)\)/g, (match, fieldName) => {
          const field = fields.find(f => f.slug === fieldName);
          return field ? min(field.id) : 0;
        });

      if (settings.numberFormat) {
        return formatNumber(math.round(math.evaluate(result), 2), this.subtenantData?.fieldsNumberFormat, settings.decimalPlaces ?? 0);
      }
      return math.round(math.evaluate(result), 2);
    },
  },
};
</script>

<style scoped>
.dragging {
  user-select: none;
}

.kanban-container {
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  width: 100%;
  min-height: 500px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
}

.kanban-board {
  display: flex;
  flex-direction: row;
  gap: 15px;
  white-space: nowrap; /* Prevent wrapping of columns */
  width: 300px;
}

.kanban-column {
  background-color: #f0f2f5;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.task-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between tasks */
}
</style>
