import { instanceApi } from '@/apps/dataSystem/api';

class TimerHelper {
  /**
   * Retrieves the timer type from the provided field object.
   *
   * This static method inspects the nested properties of the given `field` object to check
   * if a `timerType` is defined under `field.structure.ruleset`. It uses optional chaining
   * to safely access the nested properties. If the `timerType` exists, its value is returned;
   * otherwise, the function returns `null`.
   *
   * @param {Object} field - The blueprint field object that may contain timer configuration details.
   * @returns {string|null} The timer type as a string if present; otherwise, null.
   */
  static getTimerType(field) {
    if (field?.structure?.ruleset?.timerType) {
      return field?.structure?.ruleset?.timerType;
    }
    return null;
  }

  /**
   * Filters and retrieves timer actions based on the provided timer type and user ID.
   *
   * This static method checks if the given `fullTimerData` is an array. If it is, then:
   * - For a `timerType` of 'perUserTimer', it returns only the timer actions where the
   *   `userId` property matches the provided `userId`.
   * - For any other `timerType`, it returns all timer actions from the array.
   * If `fullTimerData` is not an array, the method returns an empty array.
   *
   * @param {Array} fullTimerData - An array of timer action objects.
   * @param {string} timerType - The type of timer (e.g., 'perUserTimer') that determines the filtering logic.
   * @param {string|number} userId - The user ID used to filter timer actions when the timer type is 'perUserTimer'.
   * @returns {Array} The filtered array of timer actions if applicable, or the full array if no filtering is needed.
   */
  static getTimerActions(fullTimerData, timerType, userId) {
    let timerActions = [];
    if (Array.isArray(fullTimerData)) {
      if (timerType === 'perUserTimer') {
        timerActions = fullTimerData.filter(timerAction => timerAction.userId === userId);
      } else {
        timerActions = fullTimerData;
      }
    }
    return timerActions;
  }

  /**
   * Checks if the timer is currently started based on the latest timer action.
   *
   * This static method inspects the last element in the `timerActions` array using the `at()` method.
   * If the last timer action exists and its `action` property is equal to 'start', the method returns true,
   * indicating that the timer has started. Otherwise, it returns false.
   *
   * @param {Array} timerActions - An array of timer action objects.
   * @returns {boolean} True if the latest timer action is 'start', otherwise false.
   */
  static checkIfTimerIsStarted(timerActions) {
    if (timerActions?.at(-1)) {
      const lastTimerAction = timerActions.at(-1);
      if (lastTimerAction?.action === 'start') {
        return true;
      }
    }
    return false;
  }

  /**
   * Submits a new timer record for a specific instance field.
   *
   * This static asynchronous method makes an API call to update the timer record
   * for a given field of an instance using the provided parameters. It delegates the
   * update operation to `instanceApi.patchOneTimerInstanceField`, passing along the blueprint,
   * instance, and field identifiers along with the new data.
   *
   * Note: The `blueprintId` and `fieldId` parameters should be valid MongoDB ObjectIDs.
   *
   * @param {string} blueprintId - The MongoDB ObjectID for the blueprint.
   * @param {string|number} instanceId - The unique identifier of the instance.
   * @param {string} fieldId - The MongoDB ObjectID for the field being updated.
   * @param {Object} data - The new timer record data to be submitted.
   * @returns {Promise} A promise that resolves with the response of the patch request.
   */
  static async submitNewTimerRecord(blueprintId, instanceId, fieldId, data) {
    return instanceApi.patchOneTimerInstanceField(blueprintId, instanceId, fieldId, data);
  }
}

export default TimerHelper;
