<template>
  <div>
    <a-button size="small" type="primary" @click="add"><PlusOutlined /> ADD</a-button>

    <a-divider></a-divider>

    <div v-if="!fileExportingData.length">No tree added!</div>
    <template v-else>
      <div class="mb-3">
        <a-row style="display: flex">
          <a-col span="8">
            Order type:
            <a-select
              v-model:value="localTemplate.object.fileExportingData.orderType"
              style="width: 200px"
              @change="localTemplate.object.fileExportingData.showOrder = false"
            >
              <a-select-option value="basic"> Basic </a-select-option>
              <a-select-option value="advanced"> Advanced </a-select-option>
            </a-select>
          </a-col>

          <a-col span="16" v-if="template.object.fileExportingData.orderType === 'basic'" style="display: flex; align-items: center">
            <a-checkbox :checked="template.object.fileExportingData.showOrder" @change="value => userOrderAction(value)"> Show order </a-checkbox>
          </a-col>

          <a-col span="16" v-if="template.object.fileExportingData.orderType === 'advanced'" style="display: flex; align-items: center">
            <a-select
              show-search
              :filter-option="filterOption"
              style="width: 100%"
              placeholder="Select after which tag should the tags in this item be placed after..."
              :default-value="template.object.fileExportingData?.afterTagId"
              @change="updateEvent($event)"
            >
              <a-select-opt-group v-for="optionGroup in tagsAddedForOtherItems" :key="optionGroup.id">
                <template #label>{{ optionGroup.groupName }}</template>
                <a-select-option v-for="tag in optionGroup.values" :key="tag.tagId" :value="tag.tagId">
                  {{ tag.tagLabel }} ({{ optionGroup.groupName }})
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-col>
        </a-row>
      </div>

      <NestedFileExportingItem
        :showOrder="template.object.fileExportingData?.showOrder ?? false"
        :depth="0"
        :main-item="true"
        v-model:value="fileExportingData"
        @edit="edit"
        @remove="remove"
      />
    </template>

    <a-modal v-model:open="addEditModal" :maskClosable="false" :footer="null" title="File export" :width="1200" @cancel="addEditModal = false">
      <div class="mb-3">
        <div>
          <a-switch v-model:checked="fileExporting.forceShowTagEvenIfEmpty" />
          Force show this tag even if empty
        </div>
        <div class="mb-2">
          <a-switch v-model:checked="fileExporting.computeConditions" />
          Display this tag <strong>{{ fileExporting.computeConditions ? 'BASED ON THESE CONDITIONS' : 'ALWAYS' }}</strong>
        </div>

        <div v-if="fileExporting.computeConditions">
          <FieldConditions
            v-if="selectedBlueprint"
            v-model="fileExporting.conditionings"
            :useFieldManager="true"
            :fieldConditionsBlueprint="selectedBlueprint"
            :fieldConditions="fileExporting.conditionings ?? []"
            :blueprints="blueprints"
            :selectedBlueprint="selectedBlueprint"
            :toFieldFromOtherBlueprint="true"
            :justReferenceFields="false"
            :autoFieldConditions="true"
            :mainBlueprint="selectedBlueprint"
            :mainSelectedBlueprint="selectedBlueprint"
            :cardView="true"
            :otherBlueprint="true"
            :marginTopFromParent="'20px'"
            :showConditionsNulls="true"
          ></FieldConditions>
        </div>
      </div>

      <a-card>
        <div class="row">
          <div class="col-4">
            <a-input v-model:value="fileExporting.tagLabel" placeholder="Key name (ex.: xs:Item, Item)" />
          </div>
          <div class="col-2" v-if="!(fileExporting?.children ?? []).length">
            <a-select v-model:value="fileExporting.valueType" default-value="field" style="width: 100%">
              <a-select-option :value="'field'">Field</a-select-option>
              <a-select-option :value="'static'">Static value</a-select-option>
              <a-select-option v-if="template.object.view.type === 'list'" :value="'count'">Count</a-select-option>
            </a-select>
          </div>
          <div class="col-6" v-if="!(fileExporting?.children ?? []).length">
            <div v-if="fileExporting.valueType === 'field'" style="display: flex">
              <a-select
                v-model:value="fileExporting.fieldId"
                placeholder="Field"
                :allowClear="true"
                show-search
                :filter-option="filterOption"
                style="flex-grow: 1"
                @change="setSelectedFieldType"
              >
                <a-select-option :value="'_id'">ID (number)</a-select-option>
                <a-select-option v-for="field in fields" :key="field.id" :value="field.name">
                  {{ field.label }} {{ field.type ? '(' + field.type + ')' : '' }}
                </a-select-option>
              </a-select>

              <a-tooltip
                v-if="currentlySelectedFieldType === 'number'"
                :mouseLeaveDelay="0"
                title="Force this value to have a fixed number of decimals (leave empty if not needed)"
              >
                <a-input v-model:value="fileExporting.decimals" placeholder="#" style="margin-left: 3px; width: 50px" />
              </a-tooltip>

              <a-tooltip
                v-if="currentlySelectedFieldType === 'number'"
                :mouseLeaveDelay="0"
                title="Format number acording to subtenant number format settings(ro/en). Ex: for RO: 1.234.567,89 / Ex for EN: 1,234,567.89"
              >
                <a-checkbox style="margin-left: 5px" v-model:checked="fileExporting.formatNumber" />
              </a-tooltip>

              <a-tooltip v-if="currentlySelectedFieldType === 'date'">
                <template #title>
                  <span
                    v-html="
                      `Enter a custom date format if needed. Examples: &quot;YYYY-MM-DD&quot;, &quot;MM/DD/YYYY&quot;, &quot;DD MMMM YYYY&quot;. <a href=&quot;https://momentjs.com/docs/#/displaying/format/&quot; target=&quot;_blank&quot;>More info</a>`
                    "
                  ></span>
                </template>
                <a-input v-model:value="fileExporting.dateFormat" placeholder="Date format" style="margin-left: 3px; width: 120px" />
              </a-tooltip>
            </div>

            <a-input v-else-if="fileExporting.valueType === 'static'" v-model:value="fileExporting.staticValue" placeholder="Value" />
          </div>
        </div>

        <div class="mt-3">
          <a-input v-model:value="fileExporting.description" placeholder="Description..." />
        </div>

        <div class="mt-3">
          <parameters
            :card-view="true"
            :key="parametersKey"
            :objectType="template.object.view.type"
            :availableFields="fields"
            v-model:modelValue="fileExporting.parameters"
          />
        </div>
      </a-card>

      <div class="row mt-3">
        <div class="col-1">
          <div style="padding-top: 6px">
            {{ fileExporting._id ? 'Move' : 'Insert' }}
          </div>
        </div>
        <div class="col-2">
          <a-select v-model:value="fileExporting.position" style="width: 100%">
            <a-select-option value="before">Before</a-select-option>
            <a-select-option value="after">After</a-select-option>
            <a-select-option value="as_child">As child</a-select-option>
          </a-select>
        </div>
        <div class="col-7">
          <a-tree-select
            v-model:value="fileExporting.addMoveToMenuId"
            style="width: 100%"
            :tree-data="fileExportingData"
            placeholder="Select"
            :fieldNames="{ value: '_id', children: 'children', label: 'tagLabel' }"
          />
        </div>
        <div class="col-2">
          <a-button type="primary" block @click="addFileExporting">
            <EditOutlined v-if="fileExporting._id" />
            <PlusOutlined v-else />
            {{ fileExporting._id ? 'EDIT' : 'ADD' }}
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { findAndAddMoveRecursive, findAndRemoveRecursive, objectId } from '@/core/utils/array-manipulation';
import parameters from '@/apps/templateManagement/views/Builder/components/fileExporting/components/parameters.vue';

import NestedFileExportingItem from '@/apps/templateManagement/views/Builder/components/fileExporting/components/NestedFileExportingItem.vue';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import { EditOutlined, PlusOutlined } from '@ant-design/icons-vue';
import FieldConditions from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/fieldConditions.vue';

const defaultFileExporting = {
  key: null,
  valueType: 'field',
  description: '',

  order: 0,

  fieldId: undefined,
  staticValue: null,

  position: 'before',
  parameters: [],

  forceShowTagEvenIfEmpty: false,
  computeConditions: false,
  conditionings: [],
};

export default {
  name: 'FileExporting',
  props: ['template', 'mainTemplate', 'selectedBlueprint', 'blueprints'],
  components: {
    FieldConditions,
    NestedFileExportingItem,
    parameters,
    EditOutlined,
    PlusOutlined,
  },
  data() {
    return {
      addEditModal: false,
      addEditModalFileExport: null,
      currentlySelectedFieldType: null,

      parametersKey: 1,

      fileExporting: {
        ...defaultFileExporting,
      },

      fileExportingData: [],
      localTemplate: null,
    };
  },
  async created() {
    this.fileExportingData = this.template.object?.fileExportingData?.data ?? [];
    if (this.template) {
      this.localTemplate = this.template;
    }
  },
  watch: {
    fileExportingData: {
      deep: true,
      handler(value) {
        this.localTemplate.object.fileExportingData = {
          type: 'xml',
          showOrder: this.template.object.fileExportingData?.showOrder ?? false,
          orderType: this.template.object.fileExportingData?.orderType ?? 'basic',
          afterTagId: this.template.object.fileExportingData?.afterTagId ?? '',
          data: value,
        };
      },
    },
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  computed: {
    fields() {
      const { view } = this.template.object;
      const fields = view.type === 'list' ? view.listFields : view.itemFields;
      return fields.filter(f => f.id !== 'action');
    },
    tagsAddedForOtherItems() {
      const otherItemsTags = this.getOtherItemsAddedTags();
      const otherTagsOptions = [];

      if (otherItemsTags.length) {
        otherItemsTags.forEach(itemTags => {
          if (itemTags?.fileExportingData?.data) {
            const allTags = [];
            this.getTagsFromItem(itemTags.fileExportingData.data, allTags);
            const option = {
              groupName: itemTags.name,
              values: allTags,
            };
            otherTagsOptions.push(option);
          }
        });
      }

      return otherTagsOptions;
    },
  },
  methods: {
    async updateEvent(event) {
      this.localTemplate.object.fileExportingData.afterTagId = event;
    },
    getOtherItemsAddedTags() {
      const addedTags = [];
      this.mainTemplate.forEach(column => {
        if (column.columnGroups.length) {
          this.recursiveGetObjectTags(column, addedTags);
        } else if (column.object._id !== this.template.object._id) {
          addedTags.push({
            name: column.name,
            fileExportingData: column.object.fileExportingData,
          });
        }
      });

      return addedTags;
    },
    getTagsFromItem(itemTags, allTags) {
      if (itemTags.length) {
        itemTags.forEach(item => {
          allTags.push({
            tagId: item._id,
            key: item.key,
            tagLabel: item.tagLabel,
          });

          if (item.children.length) {
            this.getTagsFromItem(item.children, allTags);
          }
        });
      }
    },
    recursiveGetObjectTags(column, addedTags) {
      if (column.columnGroups.length) {
        column.columnGroups.forEach(c => {
          if (c.columnGroups.length) {
            this.recursiveGetObjectTags(c, addedTags);
          } else if (c.object._id !== this.template.object._id) {
            addedTags.push({
              name: c.name,
              fileExportingData: c.object.fileExportingData,
            });
          }
        });
      }
    },
    setSelectedFieldType(fieldId) {
      const field = this.fields.find(f => f.id === fieldId);
      if (field) {
        this.currentlySelectedFieldType = fieldId === '_created_at' ? 'date' : (field?.type ?? '');
      } else if (fieldId === '_id') {
        this.currentlySelectedFieldType = 'number';
      }
    },
    userOrderAction(e) {
      this.localTemplate.object.fileExportingData.showOrder = e.target.checked;
      TemplatesActions.setTemplate(this.localTemplate);
    },
    add() {
      this.addEditModal = true;
      this.fileExporting = { ...defaultFileExporting };
      this.currentlySelectedFieldType = null;
    },
    async remove(fileExport) {
      const fileExportingData = [...JSON.parse(JSON.stringify(this.fileExportingData))];
      this.fileExportingData = await findAndRemoveRecursive([...fileExportingData], fileExport._id);
    },
    edit(fileExport) {
      this.addEditModal = true;

      this.addEditModalFileExport = fileExport;
      this.currentlySelectedFieldType =
        this.addEditModalFileExport.fieldId === '_created_at' ? 'date' : (this.addEditModalFileExport.fieldType ?? '');

      if (!fileExport?.parameters) {
        fileExport.parameters = [];
      }

      this.parametersKey += 1;

      this.fileExporting = JSON.parse(JSON.stringify(fileExport));
    },
    async addFileExporting() {
      if (!((this.fileExporting?.tagLabel ?? '').length > 0)) {
        return;
      }

      const { position } = this.fileExporting;
      const { addMoveToMenuId } = this.fileExporting;

      const form = {};
      const field = this.fields.find(item => item.id === this.fileExporting.fieldId);

      if (!form._id) {
        form._id = objectId();
      }

      form.key = form._id;
      form.tagLabel = this.fileExporting.tagLabel;

      form.order = this.fileExporting.order;
      form.showOrder = false;

      form.fieldId = this.fileExporting.fieldId;
      form.valueType = this.fileExporting.valueType;
      form.staticValue = this.fileExporting.staticValue;
      form.children = this.fileExporting?.children ?? [];

      form.description = this.fileExporting.description;

      form.parameters = this.fileExporting.parameters ?? [];

      form.fieldName = this.fileExporting.fieldId === '_id' ? '_id' : (field?.label ?? '');
      form.fieldType = this.fileExporting.fieldId === '_id' ? 'number' : (field?.type ?? '');
      form.decimals = this.fileExporting.decimals;
      form.formatNumber = this.fileExporting.formatNumber;
      form.dateFormat = this.fileExporting.dateFormat;
      form.forceShowTagEvenIfEmpty = this.fileExporting.forceShowTagEvenIfEmpty;
      form.computeConditions = this.fileExporting.computeConditions;
      form.conditionings = this.fileExporting.conditionings;

      const fileExportingData = [...JSON.parse(JSON.stringify(this.fileExportingData))];
      if (this.fileExporting._id) {
        this.fileExportingData = findAndAddMoveRecursive([...fileExportingData], this.fileExporting._id, form, position, addMoveToMenuId);
      } else if (addMoveToMenuId) {
        this.fileExportingData = findAndAddMoveRecursive(
          [...fileExportingData],
          addMoveToMenuId,
          {
            ...form,
          },
          position,
          addMoveToMenuId
        );
      } else {
        this.fileExportingData.push({
          ...form,
        });
      }

      this.addEditModal = false;

      this.fileExporting = { ...defaultFileExporting };
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
<style scoped></style>
