<template>
  <div
    class="permanent-modal-draggable"
    :style="{
      width: isMaximized ? '' : isMinimized ? '140px' : '50%',
      height: isMaximized ? '90%' : isMinimized ? '55px' : '50%',
      overflow: isMinimized ? 'hidden' : 'auto',
    }"
  >
    <div class="minimize-maximize-div">
      <a-button class="ant-btn ant-btn-default minimize-maximize-button" @click="toggleMinimized">
        <span v-if="isMinimized">
          <ArrowsAltOutlined />
          Maximize
        </span>
        <span v-else>
          <VerticalAlignBottomOutlined />
          Minimize
        </span>
      </a-button>
      <a-button
        class="ant-btn ant-btn-default resize-button"
        @click="toggleMaximized"
        :style="{
          display: isMinimized ? 'none' : '',
        }"
      >
        <span v-if="isMaximized">
          <ShrinkOutlined />
          Shrink
        </span>
        <span v-else>
          <ArrowsAltOutlined />
          Expand
        </span>
      </a-button>
    </div>
    <loading-spinner v-show="loading" />
    <iframe v-show="!loading" ref="iframePermanentModal" :src="`${subtenantUrl}/collections/l${menuItemPermanentModal._id}`"></iframe>
  </div>
</template>

<script>
import { VerticalAlignBottomOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons-vue';
import { SlugGetters } from '@/slug.store';

export default {
  name: 'PermanentModal',
  props: ['showPermanentModal', 'menuItemPermanentModal'],
  computed: {
    computedShowPermanentModal() {
      return this.showPermanentModal;
    },
    computedMenuItemPermanentModal() {
      return this.menuItemPermanentModal;
    },
    subtenantUrl: SlugGetters.getSubtenantUrl,
  },
  components: {
    VerticalAlignBottomOutlined,
    ArrowsAltOutlined,
    ShrinkOutlined,
  },
  data() {
    return {
      isMinimized: false,
      isMaximized: false,
      loading: true,
    };
  },
  async mounted() {
    const savedMinimizedState = localStorage.getItem('isMinimized');
    const savedMaximizedState = localStorage.getItem('isMaximized');
    if (savedMinimizedState !== null) {
      this.isMinimized = JSON.parse(savedMinimizedState);
    }
    if (savedMaximizedState !== null) {
      this.isMaximized = JSON.parse(savedMaximizedState);
    }
    await this.modifyIframe();
  },
  methods: {
    toggleMinimized() {
      this.isMinimized = !this.isMinimized;
      this.isMaximized = false;
      localStorage.setItem('isMinimized', JSON.stringify(this.isMinimized));
      localStorage.removeItem('isMaximized');
    },
    toggleMaximized() {
      this.isMaximized = !this.isMaximized;
      this.isMinimized = false;
      localStorage.setItem('isMaximized', JSON.stringify(this.isMaximized));
      localStorage.removeItem('isMinimized');
    },
    async modifyIframe() {
      const iframe = this.$refs.iframePermanentModal;
      iframe.onload = () => {
        setTimeout(() => {
          if (iframe && iframe.contentWindow && iframe.contentDocument) {
            const classesToHide = ['.topnav', '.menu-container', '.permanent-modal-draggable', '.appFooter'];
            classesToHide.forEach(className => {
              const divs = iframe.contentDocument.querySelectorAll(className);
              divs.forEach(div => {
                div.style.display = 'none';
              });
            });
            const contentContainer = iframe.contentDocument.querySelector('.content-container');
            if (contentContainer) {
              contentContainer.style.width = '100%';
            }
          }
        }, 500);
      };
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.permanent-modal-draggable {
  position: fixed !important;
  bottom: 50px !important;
  right: 15px;
  z-index: 9999;
  background: #ffffff;
  border: 1px solid #ccc;
  overflow-y: scroll;
  width: 50%;
  max-height: auto;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition:
    height 0.7s ease,
    max-height 0.7s ease,
    width 0.7s ease;
}
.permanent-modal-content {
  position: relative;
  max-height: 300px;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .permanent-modal-draggable {
    width: auto;
    left: 15px;
  }
}
.minimize-maximize-div {
  width: 100%;
  text-align: right;
  margin-top: 10px;
  display: block;
  height: auto;
}
.minimize-maximize-button,
.resize-button {
  margin-right: 15px;
}
iframe {
  width: 100%;
  height: 100vh;
  border: none;
  margin-top: 5px;
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>
