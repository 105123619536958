import moment from 'moment/moment';
import { objectId } from '@/core/utils/array-manipulation';
import { removeDiacritics } from '@/core/utils/string-manipulation';
import { formatNumber } from '@/core/utils/number-manipulation';

const DataSerializers = {
  instanceListToData(instanceList, object, blueprint, subtenantData) {
    const dataList = (instanceList ?? []).map(item => {
      const refValues = {};
      Object.keys(item).forEach(key => {
        const field = blueprint.fields.find(f => f._id === key);

        if (!key.startsWith('_')) {
          if (field && field?.structure?.type === 'file') {
            const filesArr = [];
            if (item[key] && item[key] !== '') {
              const files = JSON.parse(item[key]);
              if (Object.keys(files).length) {
                (files ?? []).forEach(file => {
                  filesArr.push(file.name);
                });
              }
            }
            refValues[key] = filesArr.join(', ');
          } else if (item[`_ref_display_val_tokens_${key}`]) {
            refValues[key] = item[`_ref_display_val_tokens_${key}`];
            refValues[`${key}-original-value`] = item[key];
          } else if (item[`_ref_display_val_${key}`]) {
            refValues[key] = item[`_ref_display_val_${key}`];
            refValues[`${key}-original-value`] = item[key];
          } else if (object.view.listFields.find(lf => lf?.id === field?.id)?.formatting?.toUppercase) {
            refValues[key] = item[key]?.toString()?.toUpperCase();
          }
        }

        if (key.startsWith('_concat_')) {
          const listFIeld = object.view.listFields.find(lf => lf?.id === key);
          if (listFIeld?.formatting?.toUppercase) {
            refValues[key] = item[key]?.toString()?.toUpperCase();
          }
        }

        if (key.startsWith('_other_blueprint_')) {
          const listFIeld = object.view.listFields.find(lf => lf?.id === key);
          if (listFIeld?.formatting?.toUppercase) {
            if (Array.isArray(item[key])) {
              refValues[key] = item[key].map(val => val?.toString()?.toUpperCase());
            } else {
              refValues[key] = item[key]?.toString()?.toUpperCase();
            }
          }
        }

        if (key.startsWith('_ref_display_val_tokens_')) {
          const listFIeld = object.view.listFields.find(lf => lf?.id === key.replace('_ref_display_val_tokens_', ''));
          if (listFIeld?.formatting?.toUppercase) {
            if (Array.isArray(item[key])) {
              refValues[key.replace('_ref_display_val_tokens_', '')] = item[key].map(val => val?.toString()?.toUpperCase());
            } else {
              refValues[key.replace('_ref_display_val_tokens_', '')] = item[key]?.toString()?.toUpperCase();
            }
          }
        }

        if (key === '_created_at') {
          const format = object?.view?.default?.dateFormat ?? 'DD.MM.YYYY, HH:mm';
          const data = moment(new Date(item[key]));
          if (data._isValid) {
            refValues[key] = data.format(format);
          } else {
            refValues[key] = item[key];
          }
        }

        if (field && field.structure.type === 'number') {
          if (!item.formated && !item.originalValues) {
            item.formated = [];
            item.originalValues = [];
          }
          if (item[key] !== null && item[key] !== '' && !item.formated[key]) {
            item.originalValues[key] = item[key] ? parseFloat(item[key]) : 0;
            item.formated[key] = true;
            if (field.formatNumber) {
              item[key] = formatNumber(parseFloat(item[key]), subtenantData?.fieldsNumberFormat, field?.structure?.options?.decimalPlaces ?? 0);
            }
          }
        }
      });
      return {
        ...item,
        ...refValues,
        key: `${item._id}-${objectId()}`,
      };
    });

    let fixedFirstRowData = null;

    if (object.view?.listFieldsCustomHeader?.enabled && object.view?.listFieldsCustomHeader?.showFirstRow) {
      fixedFirstRowData = {};
      object.view.listFieldsCustomHeader.headerColumns.forEach(col => {
        if (!col.headerColumns.length) {
          if (col.attachedField) {
            fixedFirstRowData[col.attachedField] = col.firstRowData;
          }
        } else {
          fixedFirstRowData = this.getStaticFirstRowData(fixedFirstRowData, col);
        }
      });
    }
    if (fixedFirstRowData) {
      dataList.unshift(fixedFirstRowData);
    }

    if (object.view.default?.replaceDiacritics) {
      dataList.forEach(row => {
        Object.entries(row).forEach(([key, value]) => {
          if (typeof value === 'string') {
            row[key] = removeDiacritics(value);
          } else if (Array.isArray(value)) {
            row[key] = value.map(arrVal => removeDiacritics(arrVal));
          }
        });
      });
    }

    return dataList;
  },
  getStaticFirstRowData(firstRowStaticData, col) {
    if (!col.headerColumns.length) {
      if (col.attachedField) {
        firstRowStaticData[col.attachedField] = col.firstRowData;
      }
    } else {
      col.headerColumns.forEach(subcol => {
        this.getStaticFirstRowData(firstRowStaticData, subcol);
      });
    }

    return firstRowStaticData;
  },
};
export const TemplateDataSerializer = DataSerializers;
