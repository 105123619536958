<template>
  <div>
    <a-table
      bordered
      :locale="{ emptyText: '-' }"
      size="middle"
      :columns="columns"
      :data-source="dataSource"
      :class="!this.listActionMode ? 'pointer' : ''"
      :pagination="
        object.view?.paginationType === 'frontend' && (!loadMore || loadMoreView)
          ? {
              pageSize: object.view?.itemsPerPage,
              showSizeChanger: false,
              showQuickJumper: true,
              posititon: 'bottom',
            }
          : false
      "
      :loading="instanceListLoading"
      :customRow="
        record => {
          return {
            onClick: () => {
              if (!this.listActionMode) {
                rowClick(record);
              }
            },
          };
        }
      "
      @change="fetchInstances"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="Array.isArray(record[column.key])">
          {{ record[column.key].join(', ') }}
        </template>

        <template v-if="column.dataIndex === 'nrRow' && rowsNumbering">
          {{ record['nrRow'] }}
        </template>

        <template v-if="column.key === 'is_calendar'">
          {{ record[column.dataIndex] ?? 0 }}
        </template>

        <template v-if="column.key === 'linkToReferenceInstance'">
          <div v-if="String(keyValueFromString(column, record)) !== ''">
            <template v-for="(value, i) in String(keyValueFromString(column, record)).split(',')" :key="i">
              <a-tag @click="openLinkToReferenceInstance(column, record, i)" style="white-space: normal; cursor: pointer">{{ value }}</a-tag>
            </template>
          </div>
        </template>
      </template>
      <!--      <template v-if="rowsNumbering" #nrRow="nrRow">{{ nrRow }}</template>-->

      <!--      <template #status="{ text }">{{ text._id }}</template>-->
      <!--      <template #trim-html="text, record, index, column">-->
      <!--        <div>-->
      <!--          <span v-if="keyValueFromString(column, record)?.replace(trimHtml, '').replace('&nbsp;', ' ').length > 100">{{ keyValueFromString(column, record)?.replace(trimHtml, "").replace('&nbsp;', ' ').substring(0,100)+'...' }}</span>-->
      <!--          <span v-else>{{ keyValueFromString(column, record)?.replace(trimHtml, "").replace('&nbsp;', ' ') }}</span>-->
      <!--        </div>-->
      <!--      </template>-->
    </a-table>
  </div>
</template>

<script>
import { keyValueFromString } from '@/core/utils/array-manipulation';
import moment from 'moment/moment';
import { NewTemplatesGetters } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import { formatNumber } from '@/core/utils/number-manipulation';
import { SlugGetters } from '@/slug.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';

export default {
  name: 'SimpleListTable',
  props: [
    'object',
    'blueprint',
    'dataColumns',
    'dataList',
    'userRolesWhichCanCreate',
    'loadMore',
    'loadMoreView',
    'listActionMode',
    'listActionModeDelete',
    'rowsNumbering',
    'pageNumber',
    'defaultPageSize',
    'fixedFirstRow',
  ],
  emits: ['tableFetchInstances', 'tableRowClick', 'tableOpenLinkToReferenceInstance'],
  computed: {
    moment() {
      return moment;
    },
    columns() {
      return this.rowsNumbering
        ? [
            {
              title: 'Nr. crt',
              width: 70,
              dataIndex: 'nrRow',
            },
          ].concat(this.dataColumns)
        : this.dataColumns;
    },
    dataSource() {
      if (this.rowsNumbering) {
        this.dataList.forEach((data, index) => {
          data.nrRow = index + ((this.pageNumber || 0) - 1) * (this.defaultPageSize || 0) + 1;
        });
      }

      this.dataList.map(item => {
        Object.keys(item).forEach(key => {
          const field = this.blueprint.fields.find(f => f._id === key);
          if (field && field.structure.type === 'number' && field.formatNumber) {
            if (!item.formated && !item.originalValues) {
              item.formated = [];
              item.originalValues = [];
            }
            if (item[key] !== null && item[key] !== '' && !item.formated[key]) {
              item.originalValues[key] = item[key] ? parseFloat(item[key]) : 0;
              item.formated[key] = true;
              item[key] = formatNumber(item[key], this.subtenantData?.fieldsNumberFormat, field?.structure?.options?.decimalPlaces ?? 0);
            }
          }
        });
        return {
          ...item,
        };
      });

      if (this.loadMore && !this.loadMoreView) {
        return this.dataList.slice(0, this.object.view?.itemsPerPage);
      }
      return this.dataList;
    },

    instanceListLoading() {
      return NewTemplatesGetters.getInstanceListLoading(this.object._id);
    },
    subtenantData() {
      return TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
    },
  },
  data() {
    return {
      deleteLoading: null,
    };
  },

  methods: {
    fetchInstances(pagination, filters, sorter, { action }) {
      if (this.object.view?.paginationType !== 'frontend' || action !== 'paginate') {
        this.$emit('tableFetchInstances', pagination, filters, sorter);
      }
    },

    rowClick(data) {
      this.$emit('tableRowClick', data);
    },
    openLinkToReferenceInstance(column, record, index) {
      this.$emit('tableOpenLinkToReferenceInstance', column, record, index);
    },

    keyValueFromString(column, record) {
      const value = keyValueFromString(column.key, record);
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return value;
    },
  },
};
</script>
<style scoped></style>
