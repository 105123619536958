<template>
  <a-modal v-if="rulesetComponent" :open="isVisible" @ok="onConfirm" @cancel="onCancel">
    <component :is="rulesetComponent" @update:modelValue="onInputRuleset" />
  </a-modal>
</template>

<script>
import ReferenceRuleset from './ReferenceRuleset.vue';
import PhoneNumberRuleset from './PhoneNumberRuleset.vue';
import TimerRuleset from './TimerRuleset.vue';

import { FormBuilderGetters, FormBuilderActions, FormBuilderMutations } from '../../formBuilder.store';

export default {
  name: 'FieldRulesetIndex',
  data() {
    return {
      inputRuleset: null,
    };
  },
  computed: {
    widgetTypeToCreate() {
      const widgetTypeToCreate = FormBuilderGetters.getWidgetTypeToCreate();
      return widgetTypeToCreate;
    },
    isVisible() {
      return this.widgetTypeToCreate != null;
    },
    rulesetComponent() {
      if (this.widgetTypeToCreate === 'reference_single' || this.widgetTypeToCreate === 'reference_list') {
        return ReferenceRuleset;
      }
      if (this.widgetTypeToCreate === 'phone_number') {
        return PhoneNumberRuleset;
      }
      if (this.widgetTypeToCreate === 'timer') {
        return TimerRuleset;
      }
      return null;
    },
  },
  methods: {
    onInputRuleset(inputRuleset) {
      this.inputRuleset = inputRuleset;
    },
    async onConfirm() {
      if (!this.inputRuleset) {
        return;
      }
      await FormBuilderActions.createField(this.widgetTypeToCreate, this.inputRuleset);
      FormBuilderMutations.SET_WIDGET_TYPE_TO_CREATE(null);
    },
    onCancel() {
      FormBuilderMutations.SET_WIDGET_TYPE_TO_CREATE(null);
    },
  },
};
</script>

<style></style>
